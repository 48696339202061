import React from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Form, FormField, FormItem, FormLabel, FormControl } from '@/components/ui/form';
import { useForm } from 'react-hook-form';
import { useToast } from '@/hooks/use-toast';
import { supabase } from '@/lib/supabase';

interface ExpenseFormData {
  amount: string;
  description?: string;
}

interface ExpenseFormProps {
  isOpen: boolean;
  onClose: () => void;
  expenseId?: string;
  category: string;
  currentAmount?: number;
  supplierId?: string;
  onExpenseUpdated: () => void;
}

export const ExpenseForm = ({
  isOpen,
  onClose,
  expenseId,
  category,
  currentAmount,
  supplierId,
  onExpenseUpdated
}: ExpenseFormProps) => {
  const { toast } = useToast();
  const form = useForm<ExpenseFormData>({
    defaultValues: {
      amount: currentAmount?.toString() || '',
      description: ''
    }
  });

  const onSubmit = async (data: ExpenseFormData) => {
    try {
      const amount = parseFloat(data.amount);
      
      // Update expense
      const { error: expenseError } = await supabase
        .from('wedding_expenses')
        .upsert({
          id: expenseId,
          amount,
          category,
          description: data.description,
          supplier_id: supplierId
        });

      if (expenseError) throw expenseError;

      // If this is linked to a supplier, update the supplier price too
      if (supplierId) {
        const { error: supplierError } = await supabase
          .from('user_suppliers')
          .update({ price: amount })
          .eq('supplier_id', supplierId);

        if (supplierError) throw supplierError;
      }

      toast({
        title: "Success",
        description: "Expense updated successfully",
      });
      
      onExpenseUpdated();
      onClose();
    } catch (error: any) {
      console.error('Error updating expense:', error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to update expense",
      });
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{expenseId ? 'Update' : 'Add'} {category} Expense</DialogTitle>
        </DialogHeader>
        
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <FormField
              control={form.control}
              name="amount"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Amount</FormLabel>
                  <FormControl>
                    <Input 
                      type="number" 
                      placeholder="Enter amount" 
                      {...field}
                    />
                  </FormControl>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Description (Optional)</FormLabel>
                  <FormControl>
                    <Input 
                      placeholder="Add description" 
                      {...field}
                    />
                  </FormControl>
                </FormItem>
              )}
            />

            <Button type="submit" className="w-full">
              {expenseId ? 'Update' : 'Add'} Expense
            </Button>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};