import React from 'react';
import { Card } from '@/components/ui/card';
import { cn } from '@/lib/utils';
import { UserSupplier } from './types';

interface SupplierStatsProps {
  suppliersByStatus: {
    [key: string]: UserSupplier[];
  };
}

export const SupplierStats = ({ suppliersByStatus }: SupplierStatsProps) => {
  const getStatusColor = (status: string) => {
    switch (status) {
      case 'confirmed':
        return 'border-green-500';
      case 'declined':
        return 'border-red-500';
      case 'negotiating':
        return 'border-yellow-500';
      default:
        return 'border-primary';
    }
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 mb-8">
      {Object.entries(suppliersByStatus).map(([status, suppliers]) => (
        <Card 
          key={status} 
          className={cn(
            "p-4 transition-all hover:shadow-md", 
            getStatusColor(status)
          )}
        >
          <h3 className="font-medium capitalize mb-2">{status}</h3>
          <p className="text-2xl font-bold">{suppliers.length}</p>
        </Card>
      ))}
    </div>
  );
};