import React from 'react';
import { Button } from '@/components/ui/button';
import { UserPlus, Mail, FileSpreadsheet } from 'lucide-react';
import { useToast } from '@/hooks/use-toast';
import * as XLSX from 'xlsx';
import { supabase } from '@/lib/supabase';
import { useAuth } from '@/contexts/AuthContext';

interface GuestsHeaderProps {
  onAddGuest?: () => void;
  onManageInvitations?: () => void;
}

const GuestsHeader = ({ 
  onAddGuest = () => {}, 
  onManageInvitations = () => {} 
}: GuestsHeaderProps) => {
  const { toast } = useToast();
  const { user } = useAuth();
  
  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file || !user) return;

    try {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = new Uint8Array(e.target?.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);

        console.log('Parsed Excel data:', jsonData);

        const guestsToInsert = jsonData.map((row: any) => ({
          user_id: user.id,
          first_name: row.first_name || '',
          last_name: row.last_name || '',
          email: row.email || null,
          phone: row.phone || null,
          status: row.status || 'pending',
          dietary_restrictions: row.dietary_restrictions || null,
          plus_one: row.plus_one === 'true' || row.plus_one === true || false,
          notes: row.notes || null
        }));

        const { error } = await supabase
          .from('guests')
          .insert(guestsToInsert);

        if (error) {
          console.error('Error importing guests:', error);
          toast({
            title: "Error",
            description: "Failed to import guests. Please check your file format.",
            variant: "destructive",
          });
        } else {
          toast({
            title: "Success",
            description: `Successfully imported ${guestsToInsert.length} guests`,
          });
        }
      };
      reader.readAsArrayBuffer(file);
    } catch (error) {
      console.error('Error reading file:', error);
      toast({
        title: "Error",
        description: "Failed to read the Excel file. Please try again.",
        variant: "destructive",
      });
    }
    
    // Reset the input
    event.target.value = '';
  };

  return (
    <div className="flex justify-between items-center mb-8">
      <h1 className="font-heading text-4xl md:text-5xl text-secondary">Guest List</h1>
      <div className="flex gap-2">
        <input
          type="file"
          accept=".xlsx,.xls"
          onChange={handleFileUpload}
          className="hidden"
          id="excel-upload"
        />
        <Button 
          variant="outline"
          className="gap-2"
          onClick={() => document.getElementById('excel-upload')?.click()}
        >
          <FileSpreadsheet className="h-4 w-4" />
          Import Excel
        </Button>
        <Button 
          variant="outline"
          className="gap-2" 
          onClick={onManageInvitations}
        >
          <Mail className="h-4 w-4" />
          Manage Invitations
        </Button>
        <Button className="gap-2" onClick={onAddGuest}>
          <UserPlus className="h-4 w-4" />
          Add Guest
        </Button>
      </div>
    </div>
  );
};

export default GuestsHeader;