import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Check } from "lucide-react";
import { useToast } from "@/hooks/use-toast";
import { useAuth } from "@/contexts/AuthContext";
import { supabase } from "@/lib/supabase";
import { questions } from "./onboarding/questions";
import { DateQuestion } from "./onboarding/DateQuestion";
import { RegistrationForm } from "./onboarding/RegistrationForm";
import { SingleChoiceQuestion } from "./onboarding/SingleChoiceQuestion";
import { MultipleChoiceQuestion } from "./onboarding/MultipleChoiceQuestion";
import { OnboardingCard } from "./onboarding/OnboardingCard";
import { QuestionHeader } from "./onboarding/QuestionHeader";

const Onboarding = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [answers, setAnswers] = useState<Record<string, any>>({});
  const [date, setDate] = useState<Date>();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showRegistration, setShowRegistration] = useState(false);
  const [showCustomGuestInput, setShowCustomGuestInput] = useState(false);
  const navigate = useNavigate();
  const { toast } = useToast();
  const { signUp } = useAuth();

  const handleAnswer = (value: string) => {
    if (currentQuestion.id === "3" && value === "custom") {
      setShowCustomGuestInput(true);
      setAnswers((prev) => ({ ...prev, [questions[currentStep].id]: value }));
    } else {
      setAnswers((prev) => ({ ...prev, [questions[currentStep].id]: value }));
      if (currentStep < questions.length - 1) {
        setCurrentStep((prev) => prev + 1);
      } else {
        setShowRegistration(true);
      }
    }
  };

  const handleCustomGuestCount = (value: string) => {
    if (value === "" || /^\d+$/.test(value)) {
      setAnswers((prev) => ({ ...prev, [questions[currentStep].id]: value }));
    }
  };

  const handleMultipleSelect = (value: string) => {
    const currentAnswers = answers[questions[currentStep].id] || [];
    const updatedAnswers = currentAnswers.includes(value)
      ? currentAnswers.filter((v: string) => v !== value)
      : [...currentAnswers, value];
    
    setAnswers((prev) => ({
      ...prev,
      [questions[currentStep].id]: updatedAnswers
    }));
  };

  const handleDateSelect = (selectedDate: Date | undefined) => {
    if (selectedDate) {
      setDate(selectedDate);
      setAnswers((prev) => ({ ...prev, wedding_date: selectedDate }));
      setCurrentStep((prev) => prev + 1);
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep((prev) => prev - 1);
      setShowCustomGuestInput(false);
    }
  };

  const handleSignUpAndSavePreferences = async () => {
    try {
      setIsLoading(true);
      console.log('Starting signup process...');
      
      await signUp(email, password);
      
      const { data: { user } } = await supabase.auth.getUser();
      
      if (!user) throw new Error("Failed to create account");

      console.log('User created successfully, saving preferences...');

      const { error: preferencesError } = await supabase
        .from("wedding_preferences")
        .insert({
          user_id: user.id,
          wedding_date: answers.wedding_date,
          venue_type: answers[2],
          guest_count: answers[3],
          entertainment_type: answers[4],
          budget_range: answers[10],
        });

      if (preferencesError) throw preferencesError;

      console.log('Preferences saved successfully');

      toast({
        title: "Welcome aboard! 🎉",
        description: "Your account has been created and preferences saved. Let's start planning your perfect day!",
      });

      navigate("/");
    } catch (error: any) {
      console.error("Error during signup:", error);
      toast({
        variant: "destructive",
        title: "Uh oh!",
        description: error.message || "Something went wrong while creating your account.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (showRegistration) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-background p-4">
        <OnboardingCard className="p-8 space-y-6">
          <div className="text-center space-y-4">
            <div className="mx-auto w-16 h-16 bg-primary dark:bg-primary/80 rounded-full flex items-center justify-center">
              <Check className="w-8 h-8 text-primary-foreground" />
            </div>
            <h1 className="font-serif text-3xl text-foreground">Almost there!</h1>
            <p className="text-muted-foreground">Create your account to save your preferences</p>
          </div>

          <RegistrationForm
            email={email}
            password={password}
            onEmailChange={setEmail}
            onPasswordChange={setPassword}
            onSubmit={handleSignUpAndSavePreferences}
            isLoading={isLoading}
          />
        </OnboardingCard>
      </div>
    );
  }

  const currentQuestion = questions[currentStep];

  return (
    <div className="min-h-screen flex items-center justify-center bg-background p-4">
      <OnboardingCard>
        <div className="p-8 space-y-6">
          <QuestionHeader
            currentStep={currentStep}
            totalSteps={questions.length}
            icon={currentQuestion.icon}
            title={currentQuestion.title}
          />

          {currentStep === 0 ? (
            <DateQuestion
              date={date}
              onDateSelect={handleDateSelect}
              onSkip={() => {
                setAnswers((prev) => ({ ...prev, wedding_date: null }));
                setCurrentStep((prev) => prev + 1);
              }}
              onBack={currentStep > 0 ? handleBack : undefined}
            />
          ) : currentQuestion.type === "multiple" ? (
            <MultipleChoiceQuestion
              question={currentQuestion}
              selectedAnswers={answers[currentQuestion.id] || []}
              onAnswer={handleMultipleSelect}
              onNext={() => setCurrentStep((prev) => prev + 1)}
              onBack={handleBack}
            />
          ) : (
            <SingleChoiceQuestion
              question={currentQuestion}
              currentAnswer={answers[currentQuestion.id]}
              onAnswer={handleAnswer}
              onBack={handleBack}
              showCustomInput={showCustomGuestInput}
              customInputValue={answers[currentQuestion.id]}
              onCustomInputChange={handleCustomGuestCount}
            />
          )}
        </div>
      </OnboardingCard>
    </div>
  );
};

export default Onboarding;
