import { useNavigate } from 'react-router-dom';
import { Card } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { PlanningTask, TaskStatus } from './types';
import { useQuery } from '@tanstack/react-query';
import { supabase } from '@/lib/supabase';
import { useAuth } from '@/contexts/AuthContext';
import { useEffect } from 'react';

interface TaskCardProps {
  task: PlanningTask;
  onStatusChange: (status: TaskStatus) => void;
}

const TaskCard = ({ task, onStatusChange }: TaskCardProps) => {
  const navigate = useNavigate();
  const Icon = task.icon;
  const { user } = useAuth();
  
  // Map task types to supplier categories
  const getSupplierCategory = (taskType: string) => {
    const categoryMap: { [key: string]: string } = {
      'venue': 'location',
      'entertainment': 'dj',
      'dress': 'dress',
      'photo': 'photo',
      'catering': 'catering',
      'decor': 'decor',
      'planner': 'planner'
    };
    return categoryMap[taskType];
  };

  // Query to check if there's a confirmed supplier for this task type
  const { data: confirmedSupplier } = useQuery({
    queryKey: ['confirmedSupplier', task.type, user?.id],
    queryFn: async () => {
      if (!user) return null;
      
      const category = getSupplierCategory(task.type);
      console.log('Checking confirmed supplier for category:', category);
      
      if (!category) return null; // Skip query for non-supplier tasks
      
      const { data, error } = await supabase
        .from('user_suppliers')
        .select('*')
        .eq('user_id', user.id)
        .eq('category', category)
        .eq('status', 'confirmed')
        .maybeSingle();

      if (error) {
        console.error('Error fetching confirmed supplier:', error);
        throw error;
      }
      
      console.log('Confirmed supplier data:', data);
      return data;
    },
    enabled: !!user && task.type !== 'guest' && task.type !== 'timeline',
  });

  // Update task status when supplier is confirmed
  useEffect(() => {
    if (confirmedSupplier && task.status !== 'completed') {
      console.log('Supplier confirmed, updating task status:', task.type);
      onStatusChange('completed');
    }
  }, [confirmedSupplier, task.status, task.type, onStatusChange]);
  
  const handleAction = () => {
    if (task.status === 'completed') {
      navigateToSection(task.type);
    } else {
      onStatusChange('in-progress');
      navigateToSection(task.type);
    }
  };

  const navigateToSection = (type: string) => {
    switch (type) {
      case 'venue':
        navigate('/suppliers', { state: { defaultCategory: 'location' } });
        break;
      case 'entertainment':
        navigate('/suppliers', { state: { defaultCategory: 'dj' } });
        break;
      case 'dress':
        navigate('/suppliers', { state: { defaultCategory: 'dress' } });
        break;
      case 'photo':
        navigate('/suppliers', { state: { defaultCategory: 'photo' } });
        break;
      case 'catering':
        navigate('/suppliers', { state: { defaultCategory: 'catering' } });
        break;
      case 'decor':
        navigate('/suppliers', { state: { defaultCategory: 'decor' } });
        break;
      default:
        navigate('/suppliers');
        break;
    }
  };

  return (
    <Card className="p-4">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-3">
          <div className={`p-2 rounded-full ${
            task.status === 'completed' ? 'bg-green-100 dark:bg-green-900/30' : 
            task.status === 'in-progress' ? 'bg-blue-100 dark:bg-blue-900/30' : 
            'bg-gray-100 dark:bg-gray-800'
          }`}>
            <Icon className={`h-5 w-5 ${
              task.status === 'completed' ? 'text-green-600 dark:text-green-400' :
              task.status === 'in-progress' ? 'text-blue-600 dark:text-blue-400' :
              'text-gray-600 dark:text-gray-400'
            }`} />
          </div>
          <div>
            <h4 className="font-medium text-gray-900 dark:text-gray-100">{task.title}</h4>
            <p className="text-sm text-gray-600 dark:text-gray-400">{task.description}</p>
          </div>
        </div>
        <Button 
          variant={task.status === 'completed' ? "secondary" : "default"}
          onClick={handleAction}
        >
          {task.status === 'completed' ? 'View' : 'Start'}
        </Button>
      </div>
    </Card>
  );
};

export const TaskList = ({ tasks, onTaskStatusChange }: { 
  tasks: PlanningTask[];
  onTaskStatusChange: (taskId: string, status: TaskStatus) => void;
}) => {
  return (
    <div className="space-y-4">
      {tasks.map((task) => (
        <TaskCard
          key={task.id}
          task={task}
          onStatusChange={(newStatus) => onTaskStatusChange(task.id, newStatus)}
        />
      ))}
    </div>
  );
};