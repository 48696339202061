import React, { useEffect } from 'react';
import { Card } from '@/components/ui/card';
import { useAuth } from '@/contexts/AuthContext';
import { supabase } from '@/lib/supabase';
import { useToast } from '@/hooks/use-toast';
import { ChecklistHeader } from './ChecklistHeader';
import { AddChecklistItem } from './AddChecklistItem';
import { ChecklistItem } from './ChecklistItem';
import { useChecklistState } from './ChecklistState';
import { useChecklistOperations } from './useChecklistOperations';
import { useQuery } from '@tanstack/react-query';

interface ChecklistItem {
  id: string;
  title: string;
  completed: boolean;
  user_id: string;
  due_date: string | null;
  archived: boolean;
}

interface WeddingChecklistProps {
  onUpdate?: () => void;
}

export const WeddingChecklist = ({ onUpdate }: WeddingChecklistProps) => {
  const { user } = useAuth();
  const {
    isAdding,
    newItemTitle,
    newItemDate,
    setIsAdding,
    setNewItemTitle,
    setNewItemDate,
    reset
  } = useChecklistState();

  const { addItem, toggleItem, archiveItem, deleteItem } = useChecklistOperations();

  const { data: items = [] } = useQuery({
    queryKey: ['checklist-items', user?.id],
    queryFn: async () => {
      if (!user) return [];
      console.log('Fetching checklist items for user:', user.id);
      const { data, error } = await supabase
        .from('checklist_items')
        .select('*')
        .eq('archived', false)
        .order('created_at', { ascending: true });

      if (error) throw error;
      return data || [];
    },
    enabled: !!user,
  });

  useEffect(() => {
    onUpdate?.();
  }, [items, onUpdate]);

  const handleAddItem = async () => {
    await addItem(newItemTitle, newItemDate);
    reset();
  };

  return (
    <Card className="p-6">
      <ChecklistHeader onAddClick={() => setIsAdding(true)} />

      {isAdding && (
        <AddChecklistItem
          value={newItemTitle}
          date={newItemDate}
          onChange={setNewItemTitle}
          onDateChange={setNewItemDate}
          onAdd={handleAddItem}
          onCancel={reset}
        />
      )}

      <div className="space-y-2">
        {items.length === 0 ? (
          <p className="text-muted-foreground text-center py-4">
            No tasks added yet. Click "Add Task" to get started!
          </p>
        ) : (
          items.map((item) => (
            <ChecklistItem
              key={item.id}
              id={item.id}
              title={item.title}
              completed={item.completed}
              onToggle={toggleItem}
              onDelete={deleteItem}
              onArchive={archiveItem}
              dueDate={item.due_date}
            />
          ))
        )}
      </div>
    </Card>
  );
};