import React from 'react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { useToast } from '@/hooks/use-toast';
import { supabase } from '@/lib/supabase';
import { MessageSquare } from 'lucide-react';
import { Alert, AlertDescription } from '@/components/ui/alert';

interface GuestSMSProps {
  guest: any;
  onClose: () => void;
}

const GuestSMS = ({ guest, onClose }: GuestSMSProps) => {
  const [message, setMessage] = React.useState('');
  const [isSending, setIsSending] = React.useState(false);
  const [verificationNeeded, setVerificationNeeded] = React.useState(false);
  const { toast } = useToast();

  const formatPhoneNumber = (phone: string) => {
    try {
      // Remove any non-digit characters except +
      const cleaned = phone.replace(/[^\d+]/g, '');
      
      // For Israeli numbers
      if (cleaned.startsWith('0')) {
        // Remove leading 0 and add +972
        return '+972' + cleaned.substring(1);
      }
      
      // If it already has a country code (starts with +)
      if (cleaned.startsWith('+')) {
        return cleaned;
      }
      
      // If no country code and doesn't start with 0, assume Israeli number
      return '+972' + cleaned;
    } catch (error) {
      console.error('Error formatting phone number:', error);
      throw new Error('Invalid phone number format');
    }
  };

  const sendSMS = async () => {
    if (!message.trim()) {
      toast({
        title: "Error",
        description: "Please enter a message",
        variant: "destructive",
      });
      return;
    }

    if (!guest.phone) {
      toast({
        title: "Error",
        description: "Guest has no phone number",
        variant: "destructive",
      });
      return;
    }

    setIsSending(true);
    setVerificationNeeded(false);
    
    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) throw new Error('No session');

      const formattedPhone = formatPhoneNumber(guest.phone);
      console.log('Sending SMS to:', formattedPhone, 'Message:', message);
      
      const { data, error } = await supabase.functions.invoke('send-sms', {
        body: {
          to: formattedPhone,
          message: message,
        }
      });

      console.log('SMS response:', data, error);

      if (error) {
        // Check if this is a verification needed error
        if (error.message?.includes('unverified') || error.message?.includes('verify')) {
          setVerificationNeeded(true);
          throw new Error('Phone number needs to be verified in Twilio');
        }
        throw error;
      }
      
      if (!data?.success) throw new Error(data?.error || 'Failed to send SMS');

      // Update the guest record with the sent timestamp
      const { error: updateError } = await supabase
        .from('guests')
        .update({ 
          invitation_sent_at: new Date().toISOString() 
        })
        .eq('id', guest.id);

      if (updateError) throw updateError;

      toast({
        title: "Success",
        description: "SMS sent successfully",
      });
      onClose();
    } catch (error: any) {
      console.error('Error sending SMS:', error);
      toast({
        title: "Error",
        description: error.message || "Failed to send SMS",
        variant: "destructive",
      });
    } finally {
      setIsSending(false);
    }
  };

  return (
    <div className="space-y-4 p-4">
      <h3 className="text-lg font-semibold">Send SMS to {guest.first_name} {guest.last_name}</h3>
      
      {verificationNeeded && (
        <Alert variant="destructive" className="mb-4">
          <AlertDescription>
            This phone number needs to be verified in your Twilio trial account before sending messages. 
            Please visit the Twilio Console to verify this number.
          </AlertDescription>
        </Alert>
      )}
      
      <div className="space-y-2">
        <label className="text-sm font-medium">Phone Number</label>
        <Input value={guest.phone || ''} disabled />
      </div>
      <div className="space-y-2">
        <label className="text-sm font-medium">Message</label>
        <Textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Enter your message here..."
          className="min-h-[100px]"
        />
      </div>
      <div className="flex justify-end space-x-2">
        <Button variant="outline" onClick={onClose}>
          Cancel
        </Button>
        <Button 
          onClick={sendSMS} 
          disabled={isSending}
          className="gap-2"
        >
          <MessageSquare className="h-4 w-4" />
          {isSending ? 'Sending...' : 'Send SMS'}
        </Button>
      </div>
    </div>
  );
};

export default GuestSMS;