import React from 'react';
import { TableCell, TableRow } from '@/components/ui/table';
import { Button } from '@/components/ui/button';
import { MessageSquare, Pencil, Trash2 } from 'lucide-react';
import GuestForm from '../GuestForm';

interface GuestListRowProps {
  guest: any;
  isEditing: boolean;
  onEdit?: (guest: any) => void;
  onDelete: (id: string) => void;
  onUpdate: (formData: any) => void;
  onCancelEdit?: () => void;
  onSendSMS: (guest: any) => void;
}

const GuestListRow = ({
  guest,
  isEditing,
  onEdit,
  onDelete,
  onUpdate,
  onCancelEdit,
  onSendSMS,
}: GuestListRowProps) => {
  if (isEditing) {
    return (
      <TableRow>
        <TableCell colSpan={6}>
          <GuestForm
            guest={guest}
            onSubmit={onUpdate}
            onCancel={onCancelEdit}
          />
        </TableCell>
      </TableRow>
    );
  }

  return (
    <TableRow>
      <TableCell>{guest.first_name} {guest.last_name}</TableCell>
      <TableCell>
        <div>{guest.email}</div>
        <div className="text-sm text-muted-foreground">{guest.phone}</div>
      </TableCell>
      <TableCell>
        <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
          ${guest.status === 'confirmed' ? 'bg-green-100 text-green-800' :
          guest.status === 'declined' ? 'bg-red-100 text-red-800' :
          'bg-yellow-100 text-yellow-800'}`}>
          {guest.status || 'pending'}
        </span>
      </TableCell>
      <TableCell>{guest.plus_one ? 'Yes' : 'No'}</TableCell>
      <TableCell>
        {guest.invitation_sent_at ? (
          <span className="text-sm text-muted-foreground">
            {new Date(guest.invitation_sent_at).toLocaleDateString()}
          </span>
        ) : (
          <span className="text-sm text-muted-foreground">Not sent</span>
        )}
      </TableCell>
      <TableCell>
        <div className="flex space-x-2">
          <Button
            variant="ghost"
            size="sm"
            onClick={() => onSendSMS(guest)}
            disabled={!guest.phone}
            title={guest.phone ? 'Send SMS' : 'No phone number'}
          >
            <MessageSquare className="h-4 w-4" />
          </Button>
          <Button
            variant="ghost"
            size="sm"
            onClick={() => onEdit?.(guest)}
          >
            <Pencil className="h-4 w-4" />
          </Button>
          <Button
            variant="ghost"
            size="sm"
            onClick={() => {
              if (window.confirm('Are you sure you want to delete this guest?')) {
                onDelete(guest.id);
              }
            }}
          >
            <Trash2 className="h-4 w-4" />
          </Button>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default GuestListRow;