import React from 'react';
import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import { Textarea } from '@/components/ui/textarea';
import { Card } from '@/components/ui/card';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { format } from 'date-fns';
import { CalendarIcon, Send } from 'lucide-react';
import { cn } from '@/lib/utils';
import { useToast } from '@/hooks/use-toast';
import { supabase } from '@/lib/supabase';
import { useAuth } from '@/contexts/AuthContext';

interface BulkInvitationProps {
  guests: any[];
  onClose: () => void;
}

const BulkInvitation = ({ guests, onClose }: BulkInvitationProps) => {
  const [message, setMessage] = React.useState('');
  const [weddingDate, setWeddingDate] = React.useState<Date>();
  const [sendDate, setSendDate] = React.useState<Date>();
  const [isSaving, setIsSaving] = React.useState(false);
  const { toast } = useToast();
  const { user } = useAuth();

  React.useEffect(() => {
    const fetchWeddingDetails = async () => {
      if (!user) return;
      
      try {
        const { data, error } = await supabase
          .from('wedding_details')
          .select('*')
          .eq('user_id', user.id)
          .order('created_at', { ascending: false })
          .limit(1)
          .maybeSingle();

        if (error) throw error;

        if (data) {
          if (data.wedding_date) setWeddingDate(new Date(data.wedding_date));
          if (data.invitation_send_date) setSendDate(new Date(data.invitation_send_date));
          if (data.invitation_template) setMessage(data.invitation_template);
        }
      } catch (error: any) {
        console.error('Error fetching wedding details:', error);
        toast({
          variant: "destructive",
          title: "Error",
          description: "Failed to load wedding details",
        });
      }
    };

    fetchWeddingDetails();
  }, [user]);

  const handleSave = async () => {
    if (!user) return;
    
    try {
      setIsSaving(true);
      console.log('Saving wedding details...');

      const weddingDetails = {
        user_id: user.id,
        wedding_date: weddingDate?.toISOString(),
        invitation_send_date: sendDate?.toISOString(),
        invitation_template: message,
      };

      const { error } = await supabase
        .from('wedding_details')
        .insert([weddingDetails]);

      if (error) throw error;

      toast({
        title: "Success",
        description: "Wedding details saved successfully",
      });
      
      onClose();
    } catch (error: any) {
      console.error('Error saving wedding details:', error);
      toast({
        title: "Error",
        description: error.message || "Failed to save wedding details",
        variant: "destructive",
      });
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Card className="p-6 space-y-6">
      <h2 className="text-2xl font-serif">Manage Wedding Invitations</h2>
      
      <div className="space-y-4">
        <div className="space-y-2">
          <label className="text-sm font-medium">Wedding Date</label>
          <Popover>
            <PopoverTrigger asChild>
              <Button
                variant="outline"
                className={cn(
                  "w-full justify-start text-left font-normal",
                  !weddingDate && "text-muted-foreground"
                )}
              >
                <CalendarIcon className="mr-2 h-4 w-4" />
                {weddingDate ? format(weddingDate, "PPP") : "Pick a date"}
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0" align="start">
              <Calendar
                mode="single"
                selected={weddingDate}
                onSelect={setWeddingDate}
                initialFocus
              />
            </PopoverContent>
          </Popover>
        </div>

        <div className="space-y-2">
          <label className="text-sm font-medium">Send Invitations On</label>
          <Popover>
            <PopoverTrigger asChild>
              <Button
                variant="outline"
                className={cn(
                  "w-full justify-start text-left font-normal",
                  !sendDate && "text-muted-foreground"
                )}
              >
                <CalendarIcon className="mr-2 h-4 w-4" />
                {sendDate ? format(sendDate, "PPP") : "Pick a date"}
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0" align="start">
              <Calendar
                mode="single"
                selected={sendDate}
                onSelect={setSendDate}
                initialFocus
              />
            </PopoverContent>
          </Popover>
        </div>

        <div className="space-y-2">
          <label className="text-sm font-medium">Invitation Message Template</label>
          <Textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Enter your invitation message here..."
            className="min-h-[100px]"
          />
          <p className="text-sm text-muted-foreground">
            This message will be used as a template for all guest invitations.
          </p>
        </div>
      </div>

      <div className="flex justify-end space-x-2">
        <Button variant="outline" onClick={onClose}>
          Cancel
        </Button>
        <Button 
          onClick={handleSave}
          disabled={isSaving}
          className="gap-2"
        >
          <Send className="h-4 w-4" />
          {isSaving ? 'Saving...' : 'Save Details'}
        </Button>
      </div>
    </Card>
  );
};

export default BulkInvitation;