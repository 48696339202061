import React from 'react';
import { Button } from '@/components/ui/button';
import { Store, Search, Star, Phone, Mail, MapPin, Calendar, Music2, Shirt, Users, Layout, ClipboardList, Camera } from 'lucide-react';

export const categories = [
  { id: 'location', name: 'Venues', icon: MapPin, color: 'text-blue-500' },
  { id: 'dj', name: 'DJs & Entertainment', icon: Music2, color: 'text-purple-500' },
  { id: 'dress', name: 'Wedding Dresses', icon: Shirt, color: 'text-pink-500' },
  { id: 'suits', name: 'Suits & Tuxedos', icon: Shirt, color: 'text-gray-700' },
  { id: 'decor', name: 'Decor & Tables', icon: Layout, color: 'text-amber-500' },
  { id: 'planner', name: 'Wedding Planners', icon: ClipboardList, color: 'text-green-500' },
  { id: 'catering', name: 'Catering', icon: Users, color: 'text-red-500' },
  { id: 'photo', name: 'Photography', icon: Camera, color: 'text-indigo-500' },
];

interface SupplierCategoriesProps {
  selectedCategory: string | null;
  onSelectCategory: (categoryId: string) => void;
}

export const SupplierCategories = ({ selectedCategory, onSelectCategory }: SupplierCategoriesProps) => {
  return (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-8">
      {categories.map((category) => (
        <Button
          key={category.id}
          variant={selectedCategory === category.id ? "default" : "outline"}
          className={`h-auto py-4 flex flex-col gap-2 transition-all ${
            selectedCategory === category.id 
              ? 'bg-primary hover:bg-primary/90 text-primary-foreground shadow-lg' 
              : 'bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700 border-gray-200 dark:border-gray-700'
          }`}
          onClick={() => onSelectCategory(category.id)}
        >
          <category.icon className={`h-6 w-6 ${
            selectedCategory === category.id 
              ? 'text-primary-foreground' 
              : category.color
          }`} />
          <span className={`text-sm font-medium ${
            selectedCategory === category.id 
              ? 'text-primary-foreground' 
              : 'text-gray-900 dark:text-gray-100'
          }`}>
            {category.name}
          </span>
        </Button>
      ))}
    </div>
  );
};