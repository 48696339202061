import { Link, useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { ArrowRight, Heart, Calendar, Users, Sparkles, Code, Lock } from "lucide-react";
import { motion } from "framer-motion";

const Landing = () => {
  const navigate = useNavigate();

  const handleGetStarted = () => {
    navigate('/auth/signup');
  };

  return (
    <div className="min-h-screen bg-background">
      {/* Hero Section */}
      <section className="pt-32 pb-20 px-4 bg-gradient-to-b from-muted/30 to-background">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="max-w-4xl mx-auto text-center"
        >
          <h1 className="font-serif text-5xl md:text-6xl mb-6 bg-clip-text text-transparent bg-gradient-to-r from-secondary to-primary">
            Your Dream Wedding, Perfectly Planned
          </h1>
          <p className="text-xl text-muted-foreground mb-8">
            Streamline your wedding planning journey with our AI-powered platform
          </p>
          <motion.div
            whileHover={{ scale: 1.05 }}
            transition={{ type: "spring", stiffness: 400, damping: 10 }}
          >
            <Button 
              size="lg" 
              className="gap-2 bg-gradient-to-r from-secondary to-primary hover:from-secondary/90 hover:to-primary/90" 
              onClick={handleGetStarted}
            >
              Start Planning Now <ArrowRight className="w-4 h-4" />
            </Button>
          </motion.div>
        </motion.div>
      </section>

      {/* Features Section */}
      <section className="py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="font-serif text-3xl text-center mb-12">Powered by Innovation</h2>
          <div className="grid md:grid-cols-3 gap-8">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.2 }}
            >
              <FeatureCard
                icon={Code}
                title="AI-Powered Planning"
                description="Smart algorithms that adapt to your preferences"
              />
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.4 }}
            >
              <FeatureCard
                icon={Lock}
                title="Secure Platform"
                description="Enterprise-grade security for your wedding data"
              />
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.6 }}
            >
              <FeatureCard
                icon={Sparkles}
                title="Modern Experience"
                description="Beautiful interface with real-time updates"
              />
            </motion.div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-20 bg-gradient-to-r from-secondary/10 to-primary/10">
        <motion.div 
          initial={{ opacity: 0, scale: 0.95 }}
          whileInView={{ opacity: 1, scale: 1 }}
          viewport={{ once: true }}
          className="max-w-3xl mx-auto text-center px-4"
        >
          <h2 className="font-serif text-3xl mb-6">Ready to Start Planning?</h2>
          <p className="text-muted-foreground mb-8">
            Join thousands of couples who have planned their perfect wedding with us
          </p>
          <Button 
            size="lg"
            onClick={handleGetStarted}
            className="bg-gradient-to-r from-secondary to-primary hover:from-secondary/90 hover:to-primary/90"
          >
            Create Your Free Account
          </Button>
        </motion.div>
      </section>

      {/* Footer */}
      <footer className="bg-white dark:bg-background border-t py-12">
        <div className="max-w-7xl mx-auto px-4 text-center text-muted-foreground">
          <p>© 2024 WeddyDay. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

const FeatureCard = ({ 
  icon: Icon, 
  title, 
  description 
}: { 
  icon: React.ElementType; 
  title: string; 
  description: string;
}) => (
  <motion.div 
    whileHover={{ y: -5 }}
    className="bg-white dark:bg-background/50 p-6 rounded-lg shadow-soft text-center"
  >
    <div className="inline-flex items-center justify-center w-12 h-12 rounded-full bg-gradient-to-r from-secondary to-primary mb-4">
      <Icon className="w-6 h-6 text-white" />
    </div>
    <h3 className="font-serif text-xl mb-2">{title}</h3>
    <p className="text-muted-foreground">{description}</p>
  </motion.div>
);

export default Landing;