import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet';
import { Button } from '@/components/ui/button';
import { Menu, LogOut, User } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import { NavigationItem } from './types';

interface MobileMenuProps {
  items: NavigationItem[];
  user: any;
  onLogout: () => void;
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
}

const MobileMenu = ({ items, user, onLogout, isOpen, onOpenChange }: MobileMenuProps) => {
  const navigate = useNavigate();

  return (
    <Sheet open={isOpen} onOpenChange={onOpenChange}>
      <SheetTrigger asChild>
        <Button variant="ghost" size="icon" className="btn-hover">
          <Menu className="h-6 w-6" />
        </Button>
      </SheetTrigger>
      <SheetContent side="right" className="w-[300px] sm:w-[400px]">
        <nav className="flex flex-col gap-4 mt-8">
          {items.map((item) => (
            <Link
              key={item.name}
              to={item.href}
              className="flex items-center px-4 py-2 text-sm font-medium text-muted-foreground hover:text-secondary transition-colors"
              onClick={() => onOpenChange(false)}
            >
              <item.icon className="w-5 h-5 mr-3" />
              {item.name}
            </Link>
          ))}
          {user ? (
            <>
              <Link
                to="/wedding-details"
                className="flex items-center px-4 py-2 text-sm font-medium text-muted-foreground hover:text-secondary transition-colors"
                onClick={() => onOpenChange(false)}
              >
                <User className="w-5 h-5 mr-3" />
                My Wedding
              </Link>
              <Button
                variant="ghost"
                onClick={() => {
                  onLogout();
                  onOpenChange(false);
                }}
                className="flex items-center justify-start px-4 py-2 text-sm font-medium text-muted-foreground hover:text-secondary transition-colors w-full"
              >
                <LogOut className="w-5 h-5 mr-3" />
                Logout
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="ghost"
                onClick={() => {
                  navigate('/auth/login');
                  onOpenChange(false);
                }}
                className="flex items-center justify-start px-4 py-2 text-sm font-medium w-full"
              >
                Login
              </Button>
              <Button
                onClick={() => {
                  navigate('/auth/signup');
                  onOpenChange(false);
                }}
                className="flex items-center justify-start px-4 py-2 text-sm font-medium w-full bg-gradient-to-r from-secondary to-primary text-white hover:opacity-90"
              >
                Get Started
              </Button>
            </>
          )}
        </nav>
      </SheetContent>
    </Sheet>
  );
};

export default MobileMenu;