import React from 'react';
import { Edit2 } from 'lucide-react';
import { Button } from '@/components/ui/button';

interface Expense {
  id: string;
  category: string;
  amount?: number;
  description?: string;
  supplier_id?: string;
}

interface ExpensesListProps {
  expenses: Expense[];
  currency: string;
  onEditExpense: (expense: Expense) => void;
}

export const ExpensesList = ({ expenses, currency, onEditExpense }: ExpensesListProps) => {
  return (
    <div className="space-y-6 mb-8">
      <div className="flex items-center justify-between">
        <h2 className="text-2xl font-serif">Expenses</h2>
        <Button onClick={() => onEditExpense({ category: 'Custom' } as Expense)}>
          Add Custom Expense
        </Button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {expenses.map((expense) => (
          <div key={expense.id} className="p-4 border rounded-lg">
            <div className="flex justify-between items-start mb-2">
              <div>
                <h3 className="font-medium">{expense.category}</h3>
                <p className="text-2xl">{currency} {expense.amount?.toLocaleString() || '0'}</p>
              </div>
              <Button
                variant="ghost"
                size="sm"
                onClick={() => onEditExpense(expense)}
              >
                <Edit2 className="h-4 w-4" />
              </Button>
            </div>
            {expense.description && (
              <p className="text-sm text-muted-foreground">{expense.description}</p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};