import { useAuth } from '@/contexts/AuthContext';
import { Card } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Progress } from '@/components/ui/progress';
import { useNavigate } from 'react-router-dom';
import { TaskModule } from './TaskModule';
import { StatisticsCards } from './StatisticsCards';
import { TaskList } from './TaskList';
import { useSupplierTasks } from './hooks/useSupplierTasks';
import { getNextTask } from './planningUtils';

const GuidedPlanningDashboard = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { tasks, loading, overallProgress, handleTaskStatusChange, weddingDetails } = useSupplierTasks();

  if (loading) {
    return (
      <div className="flex items-center justify-center p-8">
        <p className="text-muted-foreground">Loading your planning dashboard...</p>
      </div>
    );
  }

  return (
    <div className="space-y-6 animate-fade-in">
      <Card className="p-6">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-serif">Your Planning Progress</h2>
          <span className="text-sm text-muted-foreground">{overallProgress}% Complete</span>
        </div>
        <Progress value={overallProgress} className="mb-4" />
        <StatisticsCards tasks={tasks} />
      </Card>

      {tasks.length === 0 ? (
        <Card className="p-6">
          <p className="text-center text-muted-foreground">
            No planning tasks found. Start by setting up your wedding details.
          </p>
          <Button 
            className="mt-4 mx-auto block"
            onClick={() => navigate('/wedding-details')}
          >
            Set Up Wedding Details
          </Button>
        </Card>
      ) : (
        <>
          {getNextTask(tasks) && (
            <Card className="p-6">
              <h3 className="text-xl font-serif mb-4">Next Up: {getNextTask(tasks)?.title}</h3>
              <p className="text-muted-foreground mb-4">{getNextTask(tasks)?.description}</p>
              <TaskModule 
                task={getNextTask(tasks)!} 
                onComplete={() => handleTaskStatusChange(getNextTask(tasks)!.id, 'completed')} 
              />
            </Card>
          )}

          <TaskList 
            tasks={tasks} 
            onTaskStatusChange={handleTaskStatusChange}
          />
        </>
      )}
    </div>
  );
};

export default GuidedPlanningDashboard;