import { Routes, Route, Navigate } from "react-router-dom";
import { useAuth } from "@/contexts/AuthContext";
import Landing from "@/pages/Landing";
import Solutions from "@/pages/Solutions";
import About from "@/pages/About";
import Index from "@/pages/Index";
import Login from "@/pages/auth/Login";
import Onboarding from "@/pages/auth/Onboarding";
import Budget from "@/pages/Budget";
import Guests from "@/pages/Guests";
import Suppliers from "@/pages/Suppliers";
import Timeline from "@/pages/Timeline";
import WeddingDetails from "@/pages/WeddingDetails";
import Header from "@/components/Header";

const AppRoutes = () => {
  const { user } = useAuth();
  
  console.log('AppRoutes rendering, user:', user ? 'authenticated' : 'not authenticated');
  
  return (
    <>
      <Header />
      <Routes>
        {/* Public routes */}
        <Route path="/" element={!user ? <Landing /> : <Navigate to="/dashboard" />} />
        <Route path="/solutions" element={<Solutions />} />
        <Route path="/about" element={<About />} />
        <Route path="/auth/login" element={!user ? <Login /> : <Navigate to="/dashboard" />} />
        <Route path="/auth/signup" element={!user ? <Onboarding /> : <Navigate to="/dashboard" />} />

        {/* Protected routes - redirect to login if not authenticated */}
        <Route path="/dashboard" element={user ? <Index /> : <Navigate to="/auth/login" />} />
        <Route path="/budget" element={user ? <Budget /> : <Navigate to="/auth/login" />} />
        <Route path="/guests" element={user ? <Guests /> : <Navigate to="/auth/login" />} />
        <Route path="/suppliers" element={user ? <Suppliers /> : <Navigate to="/auth/login" />} />
        <Route path="/timeline" element={user ? <Timeline /> : <Navigate to="/auth/login" />} />
        <Route path="/wedding-details" element={user ? <WeddingDetails /> : <Navigate to="/auth/login" />} />
      </Routes>
    </>
  );
};

export default AppRoutes;