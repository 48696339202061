import React from 'react';
import { Table, TableBody } from '@/components/ui/table';
import { Dialog, DialogContent } from '@/components/ui/dialog';
import GuestSMS from './GuestSMS';
import GuestListHeader from './list/GuestListHeader';
import GuestListRow from './list/GuestListRow';
import GuestListEmpty from './list/GuestListEmpty';

interface GuestListProps {
  guests: any[];
  isLoading?: boolean;
  editingGuest?: any;
  onEdit?: (guest: any) => void;
  onDelete: (id: string) => void;
  onUpdate: (id: string, updates: any) => void;
  onCancelEdit?: () => void;
  onSendSMS: (guest: any) => void;
}

const GuestList = ({
  guests,
  isLoading = false,
  editingGuest,
  onEdit,
  onDelete,
  onUpdate,
  onCancelEdit,
  onSendSMS,
}: GuestListProps) => {
  const [selectedGuest, setSelectedGuest] = React.useState<any>(null);

  return (
    <>
      <Table>
        <GuestListHeader />
        <TableBody>
          {isLoading || guests?.length === 0 ? (
            <GuestListEmpty isLoading={isLoading} />
          ) : (
            guests?.map((guest: any) => (
              <GuestListRow
                key={guest.id}
                guest={guest}
                isEditing={editingGuest?.id === guest.id}
                onEdit={onEdit}
                onDelete={onDelete}
                onUpdate={(formData: any) => onUpdate(guest.id, formData)}
                onCancelEdit={onCancelEdit}
                onSendSMS={onSendSMS}
              />
            ))
          )}
        </TableBody>
      </Table>

      <Dialog open={!!selectedGuest} onOpenChange={() => setSelectedGuest(null)}>
        <DialogContent>
          {selectedGuest && (
            <GuestSMS
              guest={selectedGuest}
              onClose={() => setSelectedGuest(null)}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default GuestList;