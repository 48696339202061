import { useState } from 'react';
import { useAuth } from '@/contexts/AuthContext';
import { supabase } from '@/lib/supabase';
import { useToast } from './use-toast';

export interface WeddingDetailsForm {
  wedding_date: Date | undefined;
  budget: string;
  invitation_send_date: Date | undefined;
  invitation_template: string;
}

export const useWeddingDetails = () => {
  const { user } = useAuth();
  const { toast } = useToast();
  const [loading, setLoading] = useState(false);

  const fetchWeddingDetails = async () => {
    if (!user) return null;

    try {
      console.log('Fetching wedding details for user:', user.id);
      
      // Get the most recent wedding details by ordering by created_at
      const { data: existingData, error: fetchError } = await supabase
        .from('wedding_details')
        .select('*')
        .eq('user_id', user.id)
        .order('created_at', { ascending: false })
        .limit(1)
        .maybeSingle();

      if (fetchError) {
        console.error('Error fetching wedding details:', fetchError);
        throw fetchError;
      }

      if (existingData) {
        console.log('Wedding details fetched:', existingData);
        return existingData;
      }

      // If no details exist, create a new record
      console.log('No wedding details found, creating new record');
      const { data: newData, error: insertError } = await supabase
        .from('wedding_details')
        .insert([{ user_id: user.id }])
        .select()
        .maybeSingle();

      if (insertError) {
        console.error('Error creating wedding details:', insertError);
        throw insertError;
      }

      console.log('New wedding details created:', newData);
      return newData;
    } catch (error: any) {
      console.error('Error in fetchWeddingDetails:', error);
      toast({
        variant: "destructive",
        title: "Error fetching wedding details",
        description: "There was a problem loading your wedding details.",
      });
      return null;
    }
  };

  const saveWeddingDetails = async (values: WeddingDetailsForm) => {
    if (!user) return;

    setLoading(true);
    try {
      const budget = values.budget === '' ? null : parseFloat(values.budget);
      
      console.log('Saving wedding details:', {
        ...values,
        budget,
        user_id: user.id
      });

      const { error } = await supabase
        .from('wedding_details')
        .upsert({
          user_id: user.id,
          wedding_date: values.wedding_date,
          budget: budget,
          invitation_send_date: values.invitation_send_date,
          invitation_template: values.invitation_template,
        });

      if (error) throw error;

      toast({
        title: 'Success',
        description: 'Wedding details updated successfully',
      });
    } catch (error: any) {
      console.error('Error updating wedding details:', error);
      toast({
        variant: 'destructive',
        title: 'Error',
        description: 'Failed to update wedding details',
      });
    } finally {
      setLoading(false);
    }
  };

  return {
    fetchWeddingDetails,
    saveWeddingDetails,
    loading,
  };
};