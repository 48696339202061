import { useState, useEffect } from 'react';
import { useAuth } from '@/contexts/AuthContext';
import { supabase } from '@/lib/supabase';
import { useToast } from './use-toast';

interface Expense {
  id: string;
  category: string;
  amount: number;
  description?: string;
  supplier_id?: string;
}

export const useExpenses = () => {
  const { user } = useAuth();
  const { toast } = useToast();
  const [expenses, setExpenses] = useState<Expense[]>([]);
  const [loading, setLoading] = useState(true);

  const fetchExpenses = async () => {
    if (!user) return;

    try {
      console.log('Fetching expenses for user:', user.id);
      
      // First, get all confirmed suppliers
      const { data: confirmedSuppliers, error: suppliersError } = await supabase
        .from('user_suppliers')
        .select('supplier_id, category, price')
        .eq('user_id', user.id)
        .eq('status', 'confirmed');

      if (suppliersError) throw suppliersError;

      // Then, get all existing expenses
      const { data: existingExpenses, error: expensesError } = await supabase
        .from('wedding_expenses')
        .select('*')
        .eq('user_id', user.id);

      if (expensesError) throw expensesError;

      console.log('Fetched confirmed suppliers:', confirmedSuppliers);
      console.log('Fetched existing expenses:', existingExpenses);

      // Sync confirmed suppliers with expenses
      for (const supplier of confirmedSuppliers) {
        const existingExpense = existingExpenses?.find(
          e => e.category === supplier.category
        );

        // Skip if supplier_id is not a valid UUID
        if (!/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i.test(supplier.supplier_id)) {
          console.log('Skipping invalid supplier ID:', supplier.supplier_id);
          continue;
        }

        if (!existingExpense) {
          // Create new expense for confirmed supplier
          const { error: insertError } = await supabase
            .from('wedding_expenses')
            .insert({
              user_id: user.id,
              category: supplier.category,
              amount: supplier.price || 0,
              supplier_id: supplier.supplier_id
            });

          if (insertError) throw insertError;
        }
      }

      // Fetch final expenses after sync
      const { data: finalExpenses, error: finalError } = await supabase
        .from('wedding_expenses')
        .select('*')
        .eq('user_id', user.id);

      if (finalError) throw finalError;

      console.log('Final expenses after sync:', finalExpenses);
      setExpenses(finalExpenses || []);
    } catch (error: any) {
      console.error('Error in fetchExpenses:', error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to fetch expenses",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchExpenses();
  }, [user]);

  return {
    expenses,
    loading,
    refetchExpenses: fetchExpenses
  };
};