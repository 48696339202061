import { useAuth } from "@/contexts/AuthContext";
import GuidedPlanningDashboard from "@/components/guided-planning/GuidedPlanningDashboard";

const Dashboard = () => {
  const { user } = useAuth();

  if (!user) {
    return null;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <GuidedPlanningDashboard />
    </div>
  );
};

export default Dashboard;