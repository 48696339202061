import React from 'react';
import { Card } from '@/components/ui/card';
import { LucideIcon } from 'lucide-react';

interface BudgetCardProps {
  title: string;
  amount: number;
  currency: string;
  Icon: LucideIcon;
  iconClassName: string;
}

export const BudgetCard = ({ title, amount, currency, Icon, iconClassName }: BudgetCardProps) => {
  const getCurrencySymbol = (currencyCode: string) => {
    switch (currencyCode) {
      case 'USD':
        return '$';
      case 'EUR':
        return '€';
      case 'GBP':
        return '£';
      default:
        return currencyCode;
    }
  };

  return (
    <Card className="p-6">
      <div className="flex items-center justify-between">
        <div>
          <p className="text-muted-foreground">{title}</p>
          <h3 className="text-2xl font-semibold">
            {getCurrencySymbol(currency)}{amount.toLocaleString()}
          </h3>
        </div>
        <div className={`p-3 ${iconClassName} rounded-full`}>
          <Icon className="h-6 w-6" />
        </div>
      </div>
    </Card>
  );
};