import { Card } from "@/components/ui/card";
import { ReactNode } from "react";

interface OnboardingCardProps {
  children: ReactNode;
  className?: string;
}

export const OnboardingCard = ({ children, className }: OnboardingCardProps) => {
  return (
    <Card className={`w-full max-w-lg overflow-hidden border-2 border-border bg-background/50 dark:bg-background/95 backdrop-blur-sm shadow-md ${className}`}>
      {children}
    </Card>
  );
};