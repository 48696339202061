import React from 'react';
import { Card } from '@/components/ui/card';
import { useAuth } from '@/contexts/AuthContext';
import GuestsContent from '@/components/guests/GuestsContent';

const Guests = () => {
  const { user } = useAuth();

  if (!user) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <p>Please log in to view guests.</p>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-background p-4 md:p-8 animate-fade-in">
      <Card className="p-6">
        <GuestsContent />
      </Card>
    </div>
  );
};

export default Guests;