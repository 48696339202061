import React from 'react';
import { TableCell, TableRow } from '@/components/ui/table';

interface GuestListEmptyProps {
  isLoading: boolean;
}

const GuestListEmpty = ({ isLoading }: GuestListEmptyProps) => {
  return (
    <TableRow>
      <TableCell colSpan={6} className="text-center">
        {isLoading ? 'Loading...' : 'No guests added yet'}
      </TableCell>
    </TableRow>
  );
};

export default GuestListEmpty;