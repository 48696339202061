import React, { useState } from 'react';
import { ArrowUpRight, ArrowDownRight, Edit2 } from 'lucide-react';
import { useAuth } from '@/contexts/AuthContext';
import { useToast } from '@/hooks/use-toast';
import { BudgetCard } from '@/components/budget/BudgetCard';
import { ExpensesOverview } from '@/components/budget/ExpensesOverview';
import { BudgetHeader } from '@/components/budget/BudgetHeader';
import { ExpenseForm } from '@/components/budget/ExpenseForm';
import { useExpenses } from '@/hooks/useExpenses';
import { Button } from '@/components/ui/button';
import { supabase } from '@/lib/supabase';
import { ExpensesList } from '@/components/budget/ExpensesList';

const Budget = () => {
  const { user } = useAuth();
  const { toast } = useToast();
  const [isEditing, setIsEditing] = useState(false);
  const [totalBudget, setTotalBudget] = useState<number>(0);
  const [newBudget, setNewBudget] = useState<string>('');
  const [currency, setCurrency] = useState('USD');
  const { expenses, loading, refetchExpenses } = useExpenses();
  const [selectedExpense, setSelectedExpense] = useState<any>(null);
  
  const totalSpent = expenses.reduce((sum, expense) => sum + (expense.amount || 0), 0);
  const remaining = totalBudget - totalSpent;

  const handleSaveBudget = async () => {
    if (!user) return;

    const budgetNumber = parseFloat(newBudget);
    if (isNaN(budgetNumber)) {
      toast({
        title: "Invalid Budget",
        description: "Please enter a valid number",
        variant: "destructive",
      });
      return;
    }

    try {
      const { error } = await supabase
        .from('wedding_details')
        .update({ budget: budgetNumber })
        .eq('user_id', user.id);

      if (error) throw error;

      setTotalBudget(budgetNumber);
      setIsEditing(false);
      toast({
        title: "Success",
        description: "Budget updated successfully",
      });
    } catch (error) {
      console.error('Error in handleSaveBudget:', error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to update budget",
      });
    }
  };

  const handleCurrencyChange = async (newCurrency: string) => {
    if (!user) return;

    try {
      const { error } = await supabase
        .from('wedding_details')
        .update({ currency: newCurrency })
        .eq('user_id', user.id);

      if (error) throw error;

      setCurrency(newCurrency);
      toast({
        title: "Success",
        description: "Currency updated successfully",
      });
    } catch (error) {
      console.error('Error in handleCurrencyChange:', error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to update currency",
      });
    }
  };

  if (loading) {
    return <div className="p-8 text-center">Loading budget data...</div>;
  }

  return (
    <div className="min-h-screen bg-background p-4 md:p-8 animate-fade-in">
      <h1 className="font-serif text-4xl md:text-5xl text-secondary mb-8">Budget Overview</h1>
      
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
        <BudgetHeader
          totalBudget={totalBudget}
          currency={currency}
          isEditing={isEditing}
          newBudget={newBudget}
          onEditToggle={() => setIsEditing(!isEditing)}
          onBudgetChange={setNewBudget}
          onBudgetSave={handleSaveBudget}
          onCurrencyChange={handleCurrencyChange}
        />
        
        <BudgetCard
          title="Spent"
          amount={totalSpent}
          currency={currency}
          Icon={ArrowUpRight}
          iconClassName="bg-destructive/20"
        />
        
        <BudgetCard
          title="Remaining"
          amount={remaining}
          currency={currency}
          Icon={ArrowDownRight}
          iconClassName="bg-secondary/20"
        />
      </div>

      <ExpensesList
        expenses={expenses}
        currency={currency}
        onEditExpense={setSelectedExpense}
      />

      <ExpensesOverview
        categories={Object.entries(expenses.reduce((acc: { [key: string]: number }, expense) => {
          acc[expense.category] = (acc[expense.category] || 0) + (expense.amount || 0);
          return acc;
        }, {})).map(([name, amount]) => ({
          name,
          amount,
          percentage: Math.round((amount / totalSpent) * 100) || 0,
        }))}
        currency={currency}
      />

      {selectedExpense && (
        <ExpenseForm
          isOpen={!!selectedExpense}
          onClose={() => setSelectedExpense(null)}
          expenseId={selectedExpense.id}
          category={selectedExpense.category}
          currentAmount={selectedExpense.amount}
          supplierId={selectedExpense.supplier_id}
          onExpenseUpdated={refetchExpenses}
        />
      )}
    </div>
  );
};

export default Budget;