import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Form } from '@/components/ui/form';
import { useWeddingDetails } from '@/hooks/useWeddingDetails';
import { WeddingDetailsFormFields } from '@/components/wedding/WeddingDetailsForm';
import { useNavigate } from 'react-router-dom';
import { Card } from '@/components/ui/card';

const WeddingDetails = () => {
  const { fetchWeddingDetails, saveWeddingDetails, loading } = useWeddingDetails();
  const navigate = useNavigate();
  
  const form = useForm({
    defaultValues: {
      wedding_date: undefined,
      budget: '',
      invitation_send_date: undefined,
      invitation_template: '',
    },
  });

  useEffect(() => {
    const loadWeddingDetails = async () => {
      const data = await fetchWeddingDetails();
      if (data) {
        console.log('Raw data from database:', data);
        
        // Convert the date strings to Date objects before setting form values
        const formValues = {
          wedding_date: data.wedding_date ? new Date(data.wedding_date) : undefined,
          budget: data.budget?.toString() || '',
          invitation_send_date: data.invitation_send_date ? new Date(data.invitation_send_date) : undefined,
          invitation_template: data.invitation_template || '',
        };
        
        console.log('Setting form values with dates:', formValues);
        // Use setValue for each field to ensure proper form state update
        Object.entries(formValues).forEach(([key, value]) => {
          form.setValue(key as any, value);
        });
      }
    };

    loadWeddingDetails();
  }, []);

  const onSubmit = async (values: any) => {
    console.log('Submitting form with values:', values);
    await saveWeddingDetails(values);
    navigate('/dashboard');
  };

  return (
    <div className="max-w-2xl mx-auto p-6">
      <Card className="p-6">
        <h1 className="text-3xl font-serif text-secondary mb-8">My Wedding Details</h1>
        
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
            <WeddingDetailsFormFields form={form} loading={loading} />
          </form>
        </Form>
      </Card>
    </div>
  );
};

export default WeddingDetails;