import React, { ReactNode } from 'react';
import { LucideIcon } from "lucide-react";

interface QuestionHeaderProps {
  currentStep: number;
  totalSteps: number;
  icon: ReactNode;
  title: string;
}

export const QuestionHeader = ({ currentStep, totalSteps, icon, title }: QuestionHeaderProps) => {
  return (
    <>
      <div className="flex justify-between items-center">
        <span className="text-sm font-medium text-foreground">
          Step {currentStep + 1} of {totalSteps}
        </span>
        <div className="w-10 h-10 rounded-full bg-primary/30 dark:bg-primary/20 flex items-center justify-center text-primary-foreground">
          {icon}
        </div>
      </div>
      <h1 className="font-serif text-3xl text-foreground">{title}</h1>
    </>
  );
};