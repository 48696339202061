import React, { useState } from 'react';
import { DollarSign, Edit2 } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Card } from '@/components/ui/card';
import { 
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

interface BudgetHeaderProps {
  totalBudget: number;
  currency: string;
  isEditing: boolean;
  newBudget: string;
  onEditToggle: () => void;
  onBudgetChange: (value: string) => void;
  onBudgetSave: () => void;
  onCurrencyChange: (value: string) => void;
}

export const BudgetHeader = ({
  totalBudget,
  currency,
  isEditing,
  newBudget,
  onEditToggle,
  onBudgetChange,
  onBudgetSave,
  onCurrencyChange
}: BudgetHeaderProps) => {
  return (
    <Card className="p-6">
      <div className="flex items-center justify-between">
        <div className="w-full">
          <div className="flex items-center gap-4 mb-4">
            <p className="text-muted-foreground">Total Budget</p>
            <Select value={currency} onValueChange={onCurrencyChange}>
              <SelectTrigger className="w-24">
                <SelectValue placeholder="Currency" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="USD">USD</SelectItem>
                <SelectItem value="EUR">EUR</SelectItem>
                <SelectItem value="GBP">GBP</SelectItem>
              </SelectContent>
            </Select>
          </div>
          {isEditing ? (
            <div className="flex gap-2 mt-2">
              <div className="relative flex-1">
                <DollarSign className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                <Input
                  type="number"
                  value={newBudget}
                  onChange={(e) => onBudgetChange(e.target.value)}
                  className="pl-10"
                  placeholder="Enter budget"
                />
              </div>
              <Button onClick={onBudgetSave} size="sm">Save</Button>
              <Button 
                variant="outline" 
                size="sm"
                onClick={onEditToggle}
              >
                Cancel
              </Button>
            </div>
          ) : (
            <div className="flex items-center gap-2">
              <h3 className="text-2xl font-semibold">
                {totalBudget.toLocaleString()}
              </h3>
              <Button
                variant="ghost"
                size="sm"
                onClick={onEditToggle}
                className="p-1 h-auto"
              >
                <Edit2 className="h-4 w-4" />
              </Button>
            </div>
          )}
        </div>
        <div className="p-3 bg-primary/20 rounded-full">
          <DollarSign className="h-6 w-6 text-primary-foreground" />
        </div>
      </div>
    </Card>
  );
};