import React from 'react';
import { TableHead, TableHeader, TableRow } from '@/components/ui/table';

const GuestListHeader = () => {
  return (
    <TableHeader>
      <TableRow>
        <TableHead>Name</TableHead>
        <TableHead>Contact</TableHead>
        <TableHead>Status</TableHead>
        <TableHead>Plus One</TableHead>
        <TableHead>Last SMS</TableHead>
        <TableHead>Actions</TableHead>
      </TableRow>
    </TableHeader>
  );
};

export default GuestListHeader;