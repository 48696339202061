import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";

interface Option {
  value: string;
  label: string;
}

interface SingleChoiceQuestionProps {
  question: {
    id: string;
    options: Option[];
  };
  currentAnswer: string;
  onAnswer: (value: string) => void;
  onBack: () => void;
  showCustomInput?: boolean;
  customInputValue?: string;
  onCustomInputChange?: (value: string) => void;
}

export const SingleChoiceQuestion = ({
  question,
  currentAnswer,
  onAnswer,
  onBack,
  showCustomInput,
  customInputValue,
  onCustomInputChange,
}: SingleChoiceQuestionProps) => {
  return (
    <div className="space-y-4">
      <RadioGroup
        className="gap-3"
        onValueChange={onAnswer}
        defaultValue={currentAnswer}
      >
        {question.options.map((option) => (
          <div
            key={option.value}
            className="flex items-center space-x-2 bg-card dark:bg-card/80 rounded-lg p-4 cursor-pointer hover:bg-accent/50 dark:hover:bg-accent/20 transition-colors border border-border"
          >
            <RadioGroupItem value={option.value} id={option.value} />
            <label
              htmlFor={option.value}
              className="flex-grow cursor-pointer text-foreground"
            >
              {option.label}
            </label>
          </div>
        ))}
      </RadioGroup>
      {showCustomInput && (
        <div className="mt-4">
          <Input
            type="number"
            placeholder="Enter exact number of guests"
            min="1"
            className="bg-card dark:bg-card/80"
            value={customInputValue}
            onChange={(e) => onCustomInputChange?.(e.target.value)}
          />
          <Button 
            onClick={() => {
              if (customInputValue && customInputValue !== "custom") {
                onAnswer(customInputValue);
              }
            }}
            className="w-full mt-2"
            variant="secondary"
            disabled={!customInputValue || customInputValue === "custom"}
          >
            Next
          </Button>
        </div>
      )}
      <div className="flex justify-between">
        <Button
          onClick={onBack}
          variant="outline"
          className="w-24"
        >
          Back
        </Button>
        {!showCustomInput && (
          <Button 
            onClick={() => currentAnswer && onAnswer(currentAnswer)}
            className="w-24"
            variant="secondary"
            disabled={!currentAnswer}
          >
            Next
          </Button>
        )}
      </div>
    </div>
  );
};