import React from 'react';
import { Button } from '@/components/ui/button';
import { Plus } from 'lucide-react';

interface ChecklistHeaderProps {
  onAddClick: () => void;
}

export const ChecklistHeader = ({ onAddClick }: ChecklistHeaderProps) => {
  return (
    <div className="flex justify-between items-center mb-6">
      <h2 className="font-serif text-2xl">Wedding Checklist</h2>
      <Button
        variant="outline"
        size="sm"
        onClick={onAddClick}
        className="gap-2"
      >
        <Plus className="h-4 w-4" />
        Add Task
      </Button>
    </div>
  );
};