import { createClient } from '@supabase/supabase-js';

// Get the environment variables
const supabaseUrl = "https://peunxnbfudesrbkyoybg.supabase.co";
const supabaseAnonKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InBldW54bmJmdWRlc3Jia3lveWJnIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzQ5NDYyMzEsImV4cCI6MjA1MDUyMjIzMX0.RAV0o2pU3-8QoDpC2dIy0jTb3agwM-edlMONrASPiTw";

// Add detailed logging for debugging
console.log('Initializing Supabase client with:', {
  supabaseUrl: supabaseUrl ? 'present' : 'missing',
  supabaseAnonKey: supabaseAnonKey ? 'present' : 'missing'
});

if (!supabaseUrl || !supabaseAnonKey) {
  console.error('Missing Supabase credentials');
  throw new Error('Supabase credentials are required. Please check your Supabase connection in the Lovable interface.');
}

export const supabase = createClient(supabaseUrl, supabaseAnonKey);