import React from 'react';
import { Card } from '@/components/ui/card';
import { Progress } from '@/components/ui/progress';
import { CheckCircle, Flag } from 'lucide-react';

interface Milestone {
  title: string;
  completed: boolean;
}

interface PlanningMilestonesProps {
  milestones: Milestone[];
  overallProgress: number;
}

export const PlanningMilestones = ({ milestones, overallProgress }: PlanningMilestonesProps) => {
  return (
    <Card className="p-6 mb-6">
      <div className="mb-6">
        <h2 className="text-2xl font-serif mb-2">Planning Progress</h2>
        <Progress value={overallProgress} className="h-2" />
        <p className="text-sm text-muted-foreground mt-2">
          {overallProgress}% Complete
        </p>
      </div>
      
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {milestones.map((milestone, index) => (
          <div 
            key={index}
            className={`flex items-center gap-3 p-3 rounded-lg border ${
              milestone.completed ? 'bg-secondary/20 border-secondary' : 'bg-muted/20 border-muted'
            }`}
          >
            {milestone.completed ? (
              <CheckCircle className="h-5 w-5 text-secondary" />
            ) : (
              <Flag className="h-5 w-5 text-muted-foreground" />
            )}
            <span className={`text-sm ${milestone.completed ? 'font-medium' : 'text-muted-foreground'}`}>
              {milestone.title}
            </span>
          </div>
        ))}
      </div>
    </Card>
  );
};