import React from 'react';
import { Button } from '@/components/ui/button';
import { Check, Trash2, Calendar, Archive } from 'lucide-react';
import { format } from 'date-fns';

interface ChecklistItemProps {
  id: string;
  title: string;
  completed: boolean;
  dueDate: string | null;
  onToggle: (id: string) => void;
  onDelete: (id: string) => void;
  onArchive: (id: string) => void;
}

export const ChecklistItem = ({
  id,
  title,
  completed,
  dueDate,
  onToggle,
  onDelete,
  onArchive,
}: ChecklistItemProps) => {
  return (
    <div
      className={`group flex items-center justify-between p-3 rounded-lg transition-all ${
        completed
          ? 'bg-secondary/10 text-secondary-foreground'
          : 'bg-background hover:bg-muted/50'
      }`}
    >
      <div className="flex items-center gap-3 flex-1">
        <Button
          variant={completed ? 'secondary' : 'outline'}
          size="sm"
          className="h-8 w-8 p-0 rounded-full"
          onClick={() => onToggle(id)}
        >
          <Check className={`h-4 w-4 ${completed ? 'opacity-100' : 'opacity-0'}`} />
        </Button>
        <div className="flex flex-col">
          <span className={completed ? 'line-through text-muted-foreground' : ''}>
            {title}
          </span>
          {dueDate && (
            <span className="text-sm text-muted-foreground flex items-center gap-1">
              <Calendar className="h-3 w-3" />
              {format(new Date(dueDate), 'PPP')}
            </span>
          )}
        </div>
      </div>
      <div className="flex gap-2 opacity-0 group-hover:opacity-100 transition-opacity">
        <Button
          variant="outline"
          size="sm"
          onClick={() => onArchive(id)}
          className="dark:bg-secondary/20 dark:hover:bg-secondary dark:hover:text-secondary-foreground dark:border-secondary/30"
        >
          <Archive className="h-4 w-4" />
        </Button>
        <Button
          variant="outline"
          size="sm"
          onClick={() => onDelete(id)}
          className="dark:bg-destructive/20 dark:border-destructive/30 hover:bg-destructive hover:text-destructive-foreground dark:hover:bg-destructive dark:hover:text-destructive-foreground"
        >
          <Trash2 className="h-4 w-4" />
        </Button>
      </div>
    </div>
  );
};