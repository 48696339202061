import React from 'react';
import { Card, CardContent } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Calendar, Pencil, Trash2 } from 'lucide-react';
import { Droppable, Draggable } from '@hello-pangea/dnd';
import { format } from 'date-fns';
import { UserSupplier } from './types';
import { cn } from '@/lib/utils';
import { suppliersData } from './data';

interface PipelineColumnProps {
  status: string;
  suppliers: UserSupplier[];
  getSupplierDetails: (supplierId: string, category: string) => any;
  onEdit: (supplier: UserSupplier) => void;
  onDelete: (supplierId: string) => void;
}

export const PipelineColumn = ({ 
  status, 
  suppliers, 
  getSupplierDetails,
  onEdit,
  onDelete 
}: PipelineColumnProps) => {
  console.log(`Rendering PipelineColumn for status: ${status}`, suppliers);

  const getColumnStyle = (status: string) => {
    switch (status) {
      case 'declined':
        return 'bg-red-100 dark:bg-red-900/20';
      case 'confirmed':
        return 'bg-green-100 dark:bg-green-900/20';
      default:
        return 'bg-background dark:bg-gray-800/50';
    }
  };

  // Filter out suppliers where we can't find the details
  const validSuppliers = suppliers.filter(userSupplier => {
    const supplier = getSupplierDetails(userSupplier.supplier_id, userSupplier.category);
    return supplier != null;
  });

  return (
    <div className={cn("space-y-4 p-4 rounded-lg", getColumnStyle(status))}>
      <h3 className="font-medium capitalize">{status}</h3>
      <Droppable droppableId={status}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className={cn(
              "space-y-4 min-h-[200px] p-2 rounded-lg transition-colors",
              snapshot.isDraggingOver && "bg-muted"
            )}
          >
            {validSuppliers.map((userSupplier, index) => {
              const supplier = getSupplierDetails(userSupplier.supplier_id, userSupplier.category);
              
              if (!supplier) {
                console.log(`No supplier found for ID: ${userSupplier.supplier_id}, category: ${userSupplier.category}`);
                return null;
              }

              console.log(`Rendering supplier card:`, { userSupplier, supplier });

              return (
                <Draggable 
                  key={userSupplier.id} 
                  draggableId={userSupplier.id} 
                  index={index}
                >
                  {(provided, snapshot) => (
                    <Card 
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={cn(
                        "relative",
                        snapshot.isDragging && "shadow-lg"
                      )}
                    >
                      <CardContent className="p-4">
                        <div className="flex justify-between items-start mb-4">
                          <div>
                            <h3 className="font-medium">{supplier.name}</h3>
                            <p className="text-sm text-muted-foreground capitalize">{userSupplier.category}</p>
                          </div>
                          <div className="flex gap-2">
                            <Button 
                              variant="ghost" 
                              size="icon"
                              onClick={() => onEdit(userSupplier)}
                            >
                              <Pencil className="h-4 w-4" />
                            </Button>
                            <Button 
                              variant="ghost" 
                              size="icon"
                              onClick={() => onDelete(userSupplier.id)}
                            >
                              <Trash2 className="h-4 w-4" />
                            </Button>
                          </div>
                        </div>

                        {userSupplier.price && (
                          <p className="text-sm text-muted-foreground mb-2">
                            Budget/Quote: ${userSupplier.price}
                          </p>
                        )}

                        {userSupplier.meeting_date && (
                          <div className="flex items-center gap-2 text-sm">
                            <Calendar className="h-4 w-4" />
                            <span>Meeting on {format(new Date(userSupplier.meeting_date), 'PPP')}</span>
                          </div>
                        )}

                        {userSupplier.notes && (
                          <p className="text-sm mt-2 text-muted-foreground">{userSupplier.notes}</p>
                        )}
                      </CardContent>
                    </Card>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
};