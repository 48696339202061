import { format } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { cn } from "@/lib/utils";

interface DateQuestionProps {
  date: Date | undefined;
  onDateSelect: (date: Date | undefined) => void;
  onSkip: () => void;
  onBack?: () => void;
}

export const DateQuestion = ({ date, onDateSelect, onSkip, onBack }: DateQuestionProps) => {
  return (
    <div className="space-y-4">
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            className={cn(
              "w-full justify-start text-left font-normal bg-white dark:bg-gray-700",
              !date && "text-muted-foreground"
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {date ? format(date, "PPP") : "Pick a date"}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0 bg-white dark:bg-gray-800">
          <Calendar
            mode="single"
            selected={date}
            onSelect={onDateSelect}
            initialFocus
            className="bg-white dark:bg-gray-800"
          />
        </PopoverContent>
      </Popover>
      <div className="flex justify-between">
        {onBack && (
          <Button variant="outline" className="w-24" onClick={onBack}>
            Back
          </Button>
        )}
        <Button variant="secondary" className="w-24" onClick={onSkip}>
          Skip
        </Button>
      </div>
    </div>
  );
};