import { useAuth } from '@/contexts/AuthContext';
import { supabase } from '@/lib/supabase';
import { useToast } from '@/hooks/use-toast';
import { useQueryClient } from '@tanstack/react-query';
import confetti from 'canvas-confetti';

export const useChecklistOperations = () => {
  const { user } = useAuth();
  const { toast } = useToast();
  const queryClient = useQueryClient();

  const triggerConfetti = () => {
    const count = 200;
    const defaults = {
      origin: { y: 0.7 },
      zIndex: 9999,
    };

    function fire(particleRatio: number, opts: any) {
      confetti({
        ...defaults,
        ...opts,
        particleCount: Math.floor(count * particleRatio),
      });
    }

    fire(0.25, {
      spread: 26,
      startVelocity: 55,
      origin: { x: 0.2 },
    });

    fire(0.25, {
      spread: 26,
      startVelocity: 55,
      origin: { x: 0.8 },
    });

    // Play completion sound
    const audio = new Audio('/completion-sound.mp3');
    audio.play().catch(e => console.log('Audio playback failed:', e));
  };

  const addItem = async (title: string, date: Date | undefined) => {
    if (!user || !title.trim()) return;

    try {
      const { data, error } = await supabase
        .from('checklist_items')
        .insert({
          user_id: user.id,
          title: title.trim(),
          completed: false,
          due_date: date?.toISOString().split('T')[0] || null,
          archived: false,
        })
        .select()
        .single();

      if (error) throw error;

      queryClient.invalidateQueries({ queryKey: ['checklist-items'] });
      
      toast({
        title: "Success",
        description: "Checklist item added",
      });

      return data;
    } catch (error) {
      console.error('Error adding item:', error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to add checklist item",
      });
    }
  };

  const toggleItem = async (id: string) => {
    if (!user) return;

    try {
      const { data: item } = await supabase
        .from('checklist_items')
        .select()
        .eq('id', id)
        .single();

      if (!item) return;

      const { error } = await supabase
        .from('checklist_items')
        .update({ completed: !item.completed })
        .eq('id', id);

      if (error) throw error;

      queryClient.invalidateQueries({ queryKey: ['checklist-items'] });

      if (!item.completed) {
        triggerConfetti();
      }
    } catch (error) {
      console.error('Error toggling item:', error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to update checklist item",
      });
    }
  };

  const archiveItem = async (id: string) => {
    if (!user) return;

    try {
      const { error } = await supabase
        .from('checklist_items')
        .update({ archived: true })
        .eq('id', id);

      if (error) throw error;

      queryClient.invalidateQueries({ queryKey: ['checklist-items'] });
      toast({
        title: "Success",
        description: "Task archived",
      });
    } catch (error) {
      console.error('Error archiving item:', error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to archive task",
      });
    }
  };

  const deleteItem = async (id: string) => {
    if (!user) return;

    try {
      const { error } = await supabase
        .from('checklist_items')
        .delete()
        .eq('id', id);

      if (error) throw error;

      queryClient.invalidateQueries({ queryKey: ['checklist-items'] });
      toast({
        title: "Success",
        description: "Checklist item deleted",
      });
    } catch (error) {
      console.error('Error deleting item:', error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to delete checklist item",
      });
    }
  };

  return {
    addItem,
    toggleItem,
    archiveItem,
    deleteItem,
  };
};