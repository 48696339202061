import React from 'react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Check, X } from 'lucide-react';
import { ChecklistDatePicker } from './ChecklistDatePicker';

interface AddChecklistItemProps {
  value: string;
  date: Date | undefined;
  onChange: (value: string) => void;
  onDateChange: (date: Date | undefined) => void;
  onAdd: () => void;
  onCancel: () => void;
}

export const AddChecklistItem = ({
  value,
  date,
  onChange,
  onDateChange,
  onAdd,
  onCancel,
}: AddChecklistItemProps) => {
  return (
    <div className="space-y-4 mb-4">
      <div className="flex gap-2">
        <Input
          value={value}
          onChange={(e) => onChange(e.target.value)}
          placeholder="Enter new task..."
          className="flex-1"
        />
        <Button onClick={onAdd} size="sm" className="gap-2">
          <Check className="h-4 w-4" />
          Add
        </Button>
        <Button
          variant="outline"
          size="sm"
          onClick={onCancel}
        >
          <X className="h-4 w-4" />
        </Button>
      </div>
      <ChecklistDatePicker date={date} onSelect={onDateChange} />
    </div>
  );
};