import React from 'react';
import { Card } from '@/components/ui/card';
import { PieChart } from 'lucide-react';
import { ExpenseCategory } from './ExpenseCategory';

interface CategoryData {
  name: string;
  amount: number;
  percentage: number;
}

interface ExpensesOverviewProps {
  categories: CategoryData[];
  currency: string;
}

export const ExpensesOverview = ({ categories, currency }: ExpensesOverviewProps) => {
  return (
    <Card className="p-6">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-2xl font-serif">Expense Categories</h2>
        <PieChart className="h-5 w-5 text-muted-foreground" />
      </div>
      <div className="space-y-4">
        {categories.map((category) => (
          <ExpenseCategory
            key={category.name}
            name={category.name}
            amount={category.amount}
            percentage={category.percentage}
            currency={currency}
          />
        ))}
      </div>
    </Card>
  );
};