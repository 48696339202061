import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { SupplierCategories } from '@/components/suppliers/SupplierCategories';
import { SupplierListView } from '@/components/suppliers/SupplierListView';
import { MySuppliers } from '@/components/suppliers/MySuppliers';
import { PlanningMilestones } from '@/components/suppliers/PlanningMilestones';
import { useSuppliers } from '@/hooks/useSuppliers';
import { supabase } from '@/lib/supabase';
import { useAuth } from '@/contexts/AuthContext';
import { Supplier, UserSupplier } from '@/components/suppliers/types';
import { useToast } from '@/hooks/use-toast';

const Suppliers = () => {
  const location = useLocation();
  const { user } = useAuth();
  const { toast } = useToast();
  const defaultCategory = location.state?.defaultCategory || 'location';
  const [selectedCategory, setSelectedCategory] = useState(defaultCategory);
  const { data: suppliers, isLoading } = useSuppliers(selectedCategory);
  const [milestones, setMilestones] = useState([
    { title: 'Venue Booked', completed: false },
    { title: 'Invitations Sent', completed: false },
    { title: 'Budget Finalized', completed: false },
    { title: 'Catering Selected', completed: false },
    { title: 'Entertainment Booked', completed: false },
    { title: 'Photography Arranged', completed: false },
  ]);
  const [overallProgress, setOverallProgress] = useState(0);

  useEffect(() => {
    const fetchMilestoneStatus = async () => {
      if (!user) return;

      try {
        console.log('Fetching milestone status for user:', user.id);
        
        // Check venue booking
        const { data: venueData } = await supabase
          .from('user_suppliers')
          .select('*')
          .eq('user_id', user.id)
          .eq('category', 'location')
          .eq('status', 'confirmed')
          .maybeSingle();

        // Check wedding details for invitation and budget
        const { data: weddingDetails } = await supabase
          .from('wedding_details')
          .select('*')
          .eq('user_id', user.id)
          .order('created_at', { ascending: false })
          .limit(1)
          .maybeSingle();

        // Check other supplier bookings
        const { data: supplierData } = await supabase
          .from('user_suppliers')
          .select('category, status')
          .eq('user_id', user.id)
          .eq('status', 'confirmed');

        const updatedMilestones = milestones.map(milestone => {
          switch (milestone.title) {
            case 'Venue Booked':
              return { ...milestone, completed: !!venueData };
            case 'Invitations Sent':
              return { ...milestone, completed: !!weddingDetails?.invitation_send_date };
            case 'Budget Finalized':
              return { ...milestone, completed: !!weddingDetails?.budget };
            case 'Catering Selected':
              return { ...milestone, completed: supplierData?.some(s => s.category === 'catering') || false };
            case 'Entertainment Booked':
              return { ...milestone, completed: supplierData?.some(s => s.category === 'dj') || false };
            case 'Photography Arranged':
              return { ...milestone, completed: supplierData?.some(s => s.category === 'photo') || false };
            default:
              return milestone;
          }
        });

        setMilestones(updatedMilestones);

        // Calculate overall progress
        const completedMilestones = updatedMilestones.filter(m => m.completed).length;
        setOverallProgress(Math.round((completedMilestones / updatedMilestones.length) * 100));

      } catch (error) {
        console.error('Error fetching milestone status:', error);
      }
    };

    fetchMilestoneStatus();
  }, [user]);

  const handleEdit = async (supplier: UserSupplier) => {
    // Handle edit logic
    console.log('Editing supplier:', supplier);
  };

  const handleDelete = async (supplierId: string): Promise<void> => {
    try {
      const { error } = await supabase
        .from('user_suppliers')
        .delete()
        .eq('id', supplierId);

      if (error) throw error;

      toast({
        title: "Success",
        description: "Supplier removed successfully",
      });
    } catch (error: any) {
      toast({
        variant: "destructive",
        title: "Error",
        description: error.message,
      });
    }
  };

  // Transform Supplier[] to UserSupplier[]
  const transformedSuppliers = suppliers?.map(supplier => ({
    id: supplier.id,
    supplier_id: supplier.id,
    category: supplier.category,
    status: 'interested' as const,
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
  })) || [];

  return (
    <div className="container mx-auto p-4 md:p-6 space-y-8 animate-fade-in">
      {/* Planning Progress Section */}
      <section className="w-full">
        <PlanningMilestones milestones={milestones} overallProgress={overallProgress} />
      </section>
      
      {/* Suppliers Section */}
      <section className="w-full">
        <SupplierCategories
          selectedCategory={selectedCategory}
          onSelectCategory={setSelectedCategory}
        />
        <SupplierListView
          userSuppliers={transformedSuppliers}
          getSupplierDetails={(id, category) => suppliers?.find(s => s.id === id && s.category === category)}
          onEdit={handleEdit}
          onDelete={handleDelete}
        />
      </section>

      {/* Pipeline Section */}
      <section className="w-full">
        <MySuppliers />
      </section>
    </div>
  );
};

export default Suppliers;