import { Calendar as CalendarIcon, Heart, Users, Music, DollarSign } from "lucide-react";
import { Question } from "./types";

export const questions: Question[] = [
  {
    id: "1",
    title: "When is your big day?",
    options: [], // Calendar will be used instead
    icon: <CalendarIcon className="w-8 h-8" />,
    background: "#f0f4f8",
  },
  {
    id: "2",
    title: "What type of venue are you considering?",
    options: [
      { value: "indoor", label: "Indoor" },
      { value: "outdoor", label: "Outdoor" },
      { value: "both", label: "Both" },
    ],
    icon: <Heart className="w-8 h-8" />,
    background: "#e0f7fa",
  },
  {
    id: "3",
    title: "How many guests are you planning to invite?",
    type: "number",
    options: [
      { value: "less_than_50", label: "Less than 50" },
      { value: "50_100", label: "50-100" },
      { value: "more_than_100", label: "More than 100" },
      { value: "custom", label: "Other (specify number)" },
    ],
    icon: <Users className="w-8 h-8" />,
    background: "#fff3e0",
  },
  {
    id: "4",
    title: "What kind of entertainment are you thinking about?",
    type: "multiple",
    options: [
      { value: "classic_dj", label: "Classic DJ" },
      { value: "party_dj", label: "Party DJ" },
      { value: "live_band", label: "Live Band" },
      { value: "performers", label: "Performers" },
      { value: "no_preference", label: "No preference" },
    ],
    icon: <Music className="w-8 h-8" />,
    background: "#e8f5e9",
  },
  {
    id: "10",
    title: "What's your budget for the wedding?",
    options: [
      { value: "under_10k", label: "Under $10,000" },
      { value: "10k-20k", label: "$10,000 to $20,000" },
      { value: "20k-50k", label: "$20,000 to $50,000" },
      { value: "50k+", label: "Over $50,000" },
    ],
    icon: <DollarSign className="w-8 h-8" />,
    background: "#f3e5f5",
  },
];