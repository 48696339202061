import { CheckCircle, Clock, ArrowRight, Store } from 'lucide-react';
import { PlanningTask } from './types';
import { useQuery } from '@tanstack/react-query';
import { supabase } from '@/lib/supabase';
import { calculateSupplierProgress } from './planningUtils';
import { useAuth } from '@/contexts/AuthContext';

interface StatCardProps {
  title: string;
  count: number;
  icon: React.ReactNode;
  subtitle?: string;
}

const StatCard = ({ title, count, icon, subtitle }: StatCardProps) => (
  <div className="flex items-center gap-3 p-4 bg-white dark:bg-gray-800 rounded-lg shadow-soft">
    {icon}
    <div>
      <p className="text-sm text-gray-600 dark:text-gray-300">{title}</p>
      <p className="text-2xl font-semibold text-gray-900 dark:text-white">{count}</p>
      {subtitle && <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">{subtitle}</p>}
    </div>
  </div>
);

export const StatisticsCards = ({ tasks }: { tasks: PlanningTask[] }) => {
  const { user } = useAuth();

  // Query to get all user suppliers with their statuses
  const { data: userSuppliers = [] } = useQuery({
    queryKey: ['userSuppliers', user?.id],
    queryFn: async () => {
      console.log('Fetching user suppliers for stats');
      if (!user) return [];

      const { data, error } = await supabase
        .from('user_suppliers')
        .select('*')
        .eq('user_id', user.id);

      if (error) {
        console.error('Error fetching user suppliers:', error);
        throw error;
      }

      console.log('User suppliers data:', data);
      return data || [];
    },
    enabled: !!user
  });

  // Calculate completed tasks (confirmed suppliers + completed non-supplier tasks)
  const confirmedSuppliers = userSuppliers.filter(s => s.status === 'confirmed').length;
  const completedNonSupplierTasks = tasks.filter(t => 
    t.status === 'completed' && 
    !['venue', 'entertainment', 'dress', 'photo', 'catering', 'decor', 'planner'].includes(t.type)
  ).length;
  const totalCompletedTasks = confirmedSuppliers + completedNonSupplierTasks;

  // Calculate in-progress tasks (suppliers in negotiation/interested + in-progress non-supplier tasks)
  const inProgressSuppliers = userSuppliers.filter(s => 
    ['interested', 'negotiating'].includes(s.status)
  ).length;
  const inProgressNonSupplierTasks = tasks.filter(t => 
    t.status === 'in-progress' && 
    !['venue', 'entertainment', 'dress', 'photo', 'catering', 'decor', 'planner'].includes(t.type)
  ).length;
  const totalInProgressTasks = inProgressSuppliers + inProgressNonSupplierTasks;

  // Calculate upcoming tasks (pending tasks that don't have any supplier status yet)
  const upcomingTasks = tasks.filter(t => t.status === 'pending').length;

  // Calculate overall supplier progress
  const totalRequiredSuppliers = 7; // venue, entertainment, dress, photo, catering, decor, planner
  const supplierProgress = Math.round((confirmedSuppliers / totalRequiredSuppliers) * 100);

  console.log('Stats calculation:', {
    confirmedSuppliers,
    completedNonSupplierTasks,
    totalCompletedTasks,
    inProgressSuppliers,
    inProgressNonSupplierTasks,
    totalInProgressTasks,
    upcomingTasks,
    supplierProgress
  });

  return (
    <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-6">
      <StatCard
        title="Completed Tasks"
        count={totalCompletedTasks}
        icon={<CheckCircle className="h-5 w-5 text-green-500 dark:text-green-400" />}
      />
      <StatCard
        title="In Progress"
        count={totalInProgressTasks}
        icon={<Clock className="h-5 w-5 text-blue-500 dark:text-blue-400" />}
      />
      <StatCard
        title="Upcoming"
        count={upcomingTasks}
        icon={<ArrowRight className="h-5 w-5 text-gray-500 dark:text-gray-400" />}
      />
      <StatCard
        title="Supplier Progress"
        count={supplierProgress}
        icon={<Store className="h-5 w-5 text-purple-500 dark:text-purple-400" />}
        subtitle={`${confirmedSuppliers} of ${totalRequiredSuppliers} suppliers confirmed`}
      />
    </div>
  );
};