import { useQuery } from '@tanstack/react-query';
import { supabase } from '@/lib/supabase';
import { Supplier } from '@/components/suppliers/types';
import { useAuth } from '@/contexts/AuthContext';

export const useSuppliers = (category: string | null) => {
  const { user } = useAuth();

  return useQuery({
    queryKey: ['suppliers', category],
    queryFn: async () => {
      console.log('Fetching suppliers for category:', category);
      console.log('Current user:', user?.id);
      
      let query = supabase
        .from('suppliers')
        .select('*');
      
      if (category) {
        query = query.eq('category', category);
      }
      
      const { data, error } = await query;
      
      if (error) {
        console.error('Error fetching suppliers:', error);
        throw error;
      }
      
      console.log('Fetched suppliers:', data);
      return data as Supplier[];
    },
    enabled: !!user, // Only run query when user is authenticated
  });
};