import { create } from 'zustand';

interface ChecklistState {
  isAdding: boolean;
  newItemTitle: string;
  newItemDate: Date | undefined;
  setIsAdding: (isAdding: boolean) => void;
  setNewItemTitle: (title: string) => void;
  setNewItemDate: (date: Date | undefined) => void;
  reset: () => void;
}

export const useChecklistState = create<ChecklistState>((set) => ({
  isAdding: false,
  newItemTitle: '',
  newItemDate: undefined,
  setIsAdding: (isAdding) => set({ isAdding }),
  setNewItemTitle: (title) => set({ newItemTitle: title }),
  setNewItemDate: (date) => set({ newItemDate: date }),
  reset: () => set({ isAdding: false, newItemTitle: '', newItemDate: undefined }),
}));