import React from 'react';
import { Card } from '@/components/ui/card';
import { Users, Check, Mail } from 'lucide-react';

interface GuestStatsProps {
  guests: any[];
}

const GuestStats = ({ guests }: GuestStatsProps) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-4 gap-6 mb-8">
      <Card className="p-6">
        <div className="flex items-center justify-between">
          <div>
            <p className="text-muted-foreground">Total Guests</p>
            <h3 className="text-2xl font-semibold">{guests?.length || 0}</h3>
          </div>
          <div className="p-3 bg-primary/20 rounded-full">
            <Users className="h-6 w-6 text-primary-foreground" />
          </div>
        </div>
      </Card>

      <Card className="p-6">
        <div className="flex items-center justify-between">
          <div>
            <p className="text-muted-foreground">Confirmed</p>
            <h3 className="text-2xl font-semibold">
              {guests?.filter(g => g.status === 'confirmed').length || 0}
            </h3>
          </div>
          <div className="p-3 bg-secondary/20 rounded-full">
            <Check className="h-6 w-6 text-secondary" />
          </div>
        </div>
      </Card>

      <Card className="p-6">
        <div className="flex items-center justify-between">
          <div>
            <p className="text-muted-foreground">Pending</p>
            <h3 className="text-2xl font-semibold">
              {guests?.filter(g => g.status === 'pending').length || 0}
            </h3>
          </div>
          <div className="p-3 bg-accent/20 rounded-full">
            <Mail className="h-6 w-6 text-accent-foreground" />
          </div>
        </div>
      </Card>

      <Card className="p-6">
        <div className="flex items-center justify-between">
          <div>
            <p className="text-muted-foreground">Declined</p>
            <h3 className="text-2xl font-semibold">
              {guests?.filter(g => g.status === 'declined').length || 0}
            </h3>
          </div>
          <div className="p-3 bg-destructive/20 rounded-full">
            {/* Removed the X icon */}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default GuestStats;