import React, { useState, useEffect } from 'react';
import { useAuth } from '@/contexts/AuthContext';
import { supabase } from '@/lib/supabase';
import { AddEventDialog } from '@/components/timeline/AddEventDialog';
import { WeddingChecklist } from '@/components/checklist/WeddingChecklist';

interface TimelineEventType {
  id: string;
  title: string;
  description: string;
  event_date: string;
  event_time: string;
  status: 'completed' | 'in-progress' | 'upcoming';
  priority: 'high' | 'medium' | 'low';
  assigned_to?: string;
  comments?: string;
}

const Timeline = () => {
  console.log('Timeline page rendering');
  const { user } = useAuth();
  const [events, setEvents] = useState<TimelineEventType[]>([]);

  const fetchEvents = async () => {
    try {
      const { data, error } = await supabase
        .from('timeline_events')
        .select('*')
        .order('event_date', { ascending: true })
        .order('event_time', { ascending: true });

      if (error) throw error;
      setEvents(data || []);
    } catch (error) {
      console.error('Error fetching events:', error);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, [user]);

  return (
    <div className="min-h-screen bg-background p-4 md:p-8 animate-fade-in">
      <div className="flex justify-between items-center mb-8">
        <h1 className="font-serif text-4xl md:text-5xl text-secondary">Wedding Tasks</h1>
        <AddEventDialog onEventAdded={fetchEvents} />
      </div>

      <div className="space-y-6">
        {/* Wedding Checklist */}
        <WeddingChecklist />
      </div>
    </div>
  );
};

export default Timeline;