import { Link } from 'react-router-dom';
import { NavigationItem } from './types';

interface DesktopNavigationProps {
  items: NavigationItem[];
}

const DesktopNavigation = ({ items }: DesktopNavigationProps) => {
  return (
    <div className="hidden md:flex flex-1 justify-center">
      <nav className="flex space-x-8">
        {items.map((item) => (
          <Link
            key={item.name}
            to={item.href}
            className="text-muted-foreground hover:text-secondary transition-colors flex items-center gap-2"
          >
            <item.icon className="w-4 h-4" />
            {item.name}
          </Link>
        ))}
      </nav>
    </div>
  );
};

export default DesktopNavigation;