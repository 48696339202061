import React from 'react';
import { Card } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { useToast } from '@/hooks/use-toast';
import { supabase } from '@/lib/supabase';
import { useAuth } from '@/contexts/AuthContext';
import { Mail, Globe, Heart } from 'lucide-react';
import { Supplier, UserSupplier } from './types';

export interface SupplierListViewProps {
  userSuppliers: UserSupplier[];
  getSupplierDetails: (supplierId: string, category: string) => Supplier | undefined;
  onEdit: (supplier: UserSupplier) => void;
  onDelete: (supplierId: string) => Promise<void>;
}

export const SupplierListView = ({ 
  userSuppliers, 
  getSupplierDetails,
  onEdit,
  onDelete
}: SupplierListViewProps) => {
  const { toast } = useToast();
  const { user } = useAuth();

  const handleContact = (email: string) => {
    window.location.href = `mailto:${email}`;
  };

  const handleInterested = async (supplier: Supplier) => {
    if (!user) return;

    try {
      // First check if supplier already exists
      const { data: existingSupplier } = await supabase
        .from('user_suppliers')
        .select('*')
        .eq('user_id', user.id)
        .eq('supplier_id', supplier.id)
        .maybeSingle();

      if (existingSupplier) {
        toast({
          variant: "default",
          title: "Already in your list",
          description: "This supplier is already in your pipeline.",
        });
        return;
      }

      const { error } = await supabase
        .from('user_suppliers')
        .insert([{
          user_id: user.id,
          supplier_id: supplier.id,
          category: supplier.category,
          status: 'interested'
        }]);

      if (error) throw error;

      toast({
        title: "Success",
        description: "Supplier added to your interested list",
      });
    } catch (error: any) {
      console.error('Error marking supplier as interested:', error);
      toast({
        variant: "destructive",
        title: "Error",
        description: error.message,
      });
    }
  };

  const handleWebsite = (url: string | null) => {
    if (url) {
      window.open(url, '_blank');
    } else {
      toast({
        variant: "destructive",
        title: "No website available",
        description: "This supplier hasn't provided a website link.",
      });
    }
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      {userSuppliers.map((userSupplier) => {
        const supplier = getSupplierDetails(userSupplier.supplier_id, userSupplier.category);
        if (!supplier) return null;

        return (
          <Card key={userSupplier.id} className="p-4 space-y-4">
            {supplier.photos && supplier.photos.length > 0 && (
              <div className="aspect-video rounded-lg overflow-hidden">
                <img 
                  src={supplier.photos[0]} 
                  alt={supplier.name} 
                  className="w-full h-full object-cover"
                />
              </div>
            )}
            
            <div>
              <h3 className="text-lg font-semibold">{supplier.name}</h3>
              <p className="text-sm text-muted-foreground">{supplier.description}</p>
              <div className="mt-2">
                <p className="text-sm">Price: {supplier.price}</p>
                {supplier.rating && (
                  <p className="text-sm">Rating: {supplier.rating} ⭐</p>
                )}
              </div>
            </div>

            <div className="flex flex-wrap gap-2">
              <Button
                size="sm"
                variant="outline"
                onClick={() => handleContact(supplier.contact_email)}
              >
                <Mail className="w-4 h-4 mr-2" />
                Contact
              </Button>
              
              <Button
                size="sm"
                variant="outline"
                onClick={() => handleInterested(supplier)}
              >
                <Heart className="w-4 h-4 mr-2" />
                Interested
              </Button>
              
              <Button
                size="sm"
                variant="outline"
                onClick={() => handleWebsite(supplier.website_url)}
                disabled={!supplier.website_url}
              >
                <Globe className="w-4 h-4 mr-2" />
                Website
              </Button>
            </div>
          </Card>
        );
      })}
    </div>
  );
};