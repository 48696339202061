import React from 'react';

interface ExpenseCategoryProps {
  name: string;
  amount: number;
  percentage: number;
  currency: string;
}

export const ExpenseCategory = ({ name, amount, percentage, currency }: ExpenseCategoryProps) => {
  const getCurrencySymbol = (currencyCode: string) => {
    switch (currencyCode) {
      case 'USD':
        return '$';
      case 'EUR':
        return '€';
      case 'GBP':
        return '£';
      default:
        return currencyCode;
    }
  };

  return (
    <div>
      <div className="flex justify-between mb-2">
        <span className="text-sm font-medium">{name}</span>
        <span className="text-sm text-muted-foreground">
          {getCurrencySymbol(currency)}{amount.toLocaleString()} ({percentage}%)
        </span>
      </div>
      <div className="h-2 bg-muted rounded-full">
        <div
          className="h-full bg-primary rounded-full transition-all duration-300"
          style={{ width: `${percentage}%` }}
        />
      </div>
    </div>
  );
};