import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { PlanningTask } from './types';

interface TaskModuleProps {
  task: PlanningTask;
  onComplete: () => void;
}

export const TaskModule = ({ task, onComplete }: TaskModuleProps) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleAction = () => {
    let category = '';
    switch (task.type) {
      case 'venue':
        category = 'location';
        break;
      case 'entertainment':
        category = 'dj';
        break;
      case 'dress':
        category = 'dress';
        break;
      case 'photo':
        category = 'photo';
        break;
      case 'catering':
        category = 'catering';
        break;
      case 'decor':
        category = 'decor';
        break;
      case 'planner':
        category = 'planner';
        break;
      case 'guest':
        navigate('/guests');
        return;
      case 'timeline':
        navigate('/timeline');
        return;
      default:
        category = 'location';
    }
    
    navigate('/suppliers', { state: { defaultCategory: category } });
  };

  return (
    <div className="space-y-4">
      <div className="flex gap-2">
        <Button onClick={handleAction}>
          Get Started
        </Button>
        {task.resourceUrl && (
          <Button variant="outline" asChild>
            <a href={task.resourceUrl} target="_blank" rel="noopener noreferrer">
              Learn More
            </a>
          </Button>
        )}
      </div>
    </div>
  );
};