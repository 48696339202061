import { motion } from "framer-motion";
import { Heart, Users, Code, Sparkles } from "lucide-react";

const About = () => {
  const team = [
    {
      name: "Our Mission",
      role: "Why We Exist",
      description: "To make wedding planning accessible, enjoyable, and stress-free for every couple.",
      icon: Heart,
    },
    {
      name: "Our Team",
      role: "Who We Are",
      description: "A passionate group of tech enthusiasts and wedding industry experts.",
      icon: Users,
    },
    {
      name: "Our Technology",
      role: "How We Do It",
      description: "Cutting-edge AI and automation to streamline wedding planning.",
      icon: Code,
    },
    {
      name: "Our Vision",
      role: "Where We're Going",
      description: "To revolutionize the wedding industry through innovative technology.",
      icon: Sparkles,
    },
  ];

  return (
    <div className="min-h-screen bg-background pt-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center mb-16"
        >
          <h1 className="text-4xl md:text-5xl font-serif mb-6 bg-clip-text text-transparent bg-gradient-to-r from-secondary to-primary">
            About WeddyDay
          </h1>
          <p className="text-xl text-muted-foreground max-w-2xl mx-auto">
            We're reimagining wedding planning for the modern couple
          </p>
        </motion.div>

        <div className="grid md:grid-cols-2 gap-8 mb-16">
          {team.map((member, index) => {
            const Icon = member.icon;
            return (
              <motion.div
                key={member.name}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.2 }}
                className="bg-white dark:bg-background/50 p-8 rounded-lg shadow-soft text-center"
              >
                <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-gradient-to-r from-secondary to-primary mb-6">
                  <Icon className="w-8 h-8 text-white" />
                </div>
                <h3 className="text-xl font-serif mb-2">{member.name}</h3>
                <p className="text-muted-foreground mb-2">{member.role}</p>
                <p className="text-muted-foreground">{member.description}</p>
              </motion.div>
            )
          })}
        </div>

        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <div className="max-w-3xl mx-auto">
            <h2 className="text-3xl font-serif mb-6">Our Story</h2>
            <p className="text-muted-foreground mb-4">
              WeddyDay was born from a simple idea: wedding planning should be as joyful as the big day itself. We combined our passion for technology with our understanding of the wedding industry to create a platform that truly serves modern couples.
            </p>
            <p className="text-muted-foreground">
              Today, we're proud to help thousands of couples turn their dream weddings into reality, one click at a time.
            </p>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default About;