import { Store, Music2, Camera, Shirt, UtensilsCrossed, Palette, ClipboardList, Users, Calendar } from 'lucide-react';
import { PlanningTask } from './types';

export const calculateProgress = (tasks: PlanningTask[]): number => {
  if (tasks.length === 0) return 0;
  const completedTasks = tasks.filter(task => task.status === 'completed');
  return Math.round((completedTasks.length / tasks.length) * 100);
};

export const getNextTask = (tasks: PlanningTask[]): PlanningTask | null => {
  return tasks.find(task => task.status === 'in-progress') || tasks.find(task => task.status === 'pending') || null;
};

export const calculateSupplierProgress = (confirmedSuppliers: number): number => {
  // Assuming we want at least 6 key suppliers confirmed (venue, catering, photography, etc.)
  const targetSuppliers = 6;
  return Math.min(Math.round((confirmedSuppliers / targetSuppliers) * 100), 100);
};

export const generateInitialTasks = (weddingDate: Date | null, preferences: any | null): PlanningTask[] => {
  return [
    {
      id: '1',
      title: 'Choose Your Venue',
      description: 'Start by selecting the perfect venue for your special day',
      type: 'venue',
      status: 'pending',
      icon: Store,
      resourceUrl: '/resources/venue-guide',
    },
    {
      id: '2',
      title: 'Book Entertainment',
      description: 'Find the perfect entertainment for your celebration',
      type: 'entertainment',
      status: 'pending',
      icon: Music2,
      resourceUrl: '/resources/entertainment-guide',
    },
    {
      id: '3',
      title: 'Select Wedding Attire',
      description: 'Choose your dream wedding dress and accessories',
      type: 'dress',
      status: 'pending',
      icon: Shirt,
      resourceUrl: '/resources/dress-guide',
    },
    {
      id: '4',
      title: 'Book Photography',
      description: 'Capture your special moments with the right photographer',
      type: 'photo',
      status: 'pending',
      icon: Camera,
      resourceUrl: '/resources/photography-guide',
    },
    {
      id: '5',
      title: 'Plan Catering',
      description: 'Choose your perfect wedding menu',
      type: 'catering',
      status: 'pending',
      icon: UtensilsCrossed,
      resourceUrl: '/resources/catering-guide',
    },
    {
      id: '6',
      title: 'Select Decorations',
      description: 'Design your wedding aesthetic and decor',
      type: 'decor',
      status: 'pending',
      icon: Palette,
      resourceUrl: '/resources/decor-guide',
    },
    {
      id: '7',
      title: 'Hire Wedding Planner',
      description: 'Get professional help to organize your perfect day',
      type: 'planner',
      status: 'pending',
      icon: ClipboardList,
      resourceUrl: '/resources/planner-guide',
    },
    {
      id: '8',
      title: 'Manage Guest List',
      description: 'Create and organize your wedding guest list',
      type: 'guest',
      status: 'pending',
      icon: Users,
      resourceUrl: '/resources/guest-guide',
    },
    {
      id: '9',
      title: 'Create Timeline',
      description: 'Plan your wedding day schedule',
      type: 'timeline',
      status: 'pending',
      icon: Calendar,
      resourceUrl: '/resources/timeline-guide',
    },
  ];
};