import { useQuery } from '@tanstack/react-query';
import { supabase } from '@/lib/supabase';
import { useAuth } from '@/contexts/AuthContext';
import { useState, useEffect } from 'react';
import { PlanningTask } from '../types';
import { 
  Building2, 
  Music2, 
  Shirt, 
  Camera, 
  UtensilsCrossed, 
  Palette, 
  UserRound, 
  Users, 
  Calendar 
} from 'lucide-react';

export const useSupplierTasks = () => {
  const { user } = useAuth();
  const [tasks, setTasks] = useState<PlanningTask[]>([]);
  const [overallProgress, setOverallProgress] = useState(0);

  const { data: weddingDetails, isLoading } = useQuery({
    queryKey: ['weddingDetails', user?.id],
    queryFn: async () => {
      if (!user) return null;
      
      const { data, error } = await supabase
        .from('wedding_details')
        .select('*')
        .eq('user_id', user.id)
        .order('created_at', { ascending: false })
        .limit(1)
        .maybeSingle();

      if (error) throw error;
      return data;
    },
    enabled: !!user,
  });

  useEffect(() => {
    if (user) {
      // Initialize tasks based on wedding planning steps
      const initialTasks: PlanningTask[] = [
        {
          id: 'venue',
          title: 'Choose Venue',
          description: 'Select the perfect venue for your wedding',
          type: 'venue',
          status: 'pending',
          icon: Building2
        },
        {
          id: 'entertainment',
          title: 'Book Entertainment',
          description: 'Find the right entertainment for your celebration',
          type: 'entertainment',
          status: 'pending',
          icon: Music2
        },
        {
          id: 'dress',
          title: 'Find Wedding Attire',
          description: 'Choose the perfect wedding dress and suits',
          type: 'dress',
          status: 'pending',
          icon: Shirt
        },
        {
          id: 'photo',
          title: 'Book Photographer',
          description: 'Select a photographer to capture your special moments',
          type: 'photo',
          status: 'pending',
          icon: Camera
        },
        {
          id: 'catering',
          title: 'Choose Catering',
          description: 'Plan your wedding menu and catering service',
          type: 'catering',
          status: 'pending',
          icon: UtensilsCrossed
        },
        {
          id: 'decor',
          title: 'Plan Decoration',
          description: 'Design your wedding decoration and theme',
          type: 'decor',
          status: 'pending',
          icon: Palette
        },
        {
          id: 'planner',
          title: 'Hire Wedding Planner',
          description: 'Find a professional to help coordinate your wedding',
          type: 'planner',
          status: 'pending',
          icon: UserRound
        },
        {
          id: 'guest',
          title: 'Manage Guest List',
          description: 'Create and organize your wedding guest list',
          type: 'guest',
          status: 'pending',
          icon: Users
        },
        {
          id: 'timeline',
          title: 'Create Timeline',
          description: 'Plan your wedding day schedule',
          type: 'timeline',
          status: 'pending',
          icon: Calendar
        }
      ];
      setTasks(initialTasks);
    }
  }, [user]);

  const handleTaskStatusChange = async (taskId: string, status: 'pending' | 'completed') => {
    setTasks(prevTasks => {
      const newTasks = prevTasks.map(task => 
        task.id === taskId ? { ...task, status } : task
      );
      const completedCount = newTasks.filter(t => t.status === 'completed').length;
      setOverallProgress(Math.round((completedCount / newTasks.length) * 100));
      return newTasks;
    });
  };

  return { 
    weddingDetails, 
    tasks, 
    loading: isLoading, 
    overallProgress,
    handleTaskStatusChange 
  };
};