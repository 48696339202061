import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";

interface Option {
  value: string;
  label: string;
}

interface MultipleChoiceQuestionProps {
  question: {
    id: string;
    options: Option[];
  };
  selectedAnswers: string[];
  onAnswer: (value: string) => void;
  onNext: () => void;
  onBack: () => void;
}

export const MultipleChoiceQuestion = ({
  question,
  selectedAnswers,
  onAnswer,
  onNext,
  onBack,
}: MultipleChoiceQuestionProps) => {
  return (
    <div className="space-y-4">
      {question.options.map((option) => (
        <div
          key={option.value}
          className="flex items-center space-x-2 bg-white dark:bg-gray-700 rounded-lg p-4 cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-600 transition-colors"
        >
          <Checkbox
            id={option.value}
            checked={selectedAnswers.includes(option.value)}
            onCheckedChange={() => onAnswer(option.value)}
          />
          <label
            htmlFor={option.value}
            className="flex-grow cursor-pointer text-foreground"
          >
            {option.label}
          </label>
        </div>
      ))}
      <div className="flex justify-between">
        <Button
          onClick={onBack}
          variant="outline"
          className="w-24"
        >
          Back
        </Button>
        <Button 
          onClick={onNext}
          className="w-24"
          variant="secondary"
        >
          Next
        </Button>
      </div>
    </div>
  );
};