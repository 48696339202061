import { useState, useEffect } from 'react';
import { useToast } from '@/hooks/use-toast';
import { useAuth } from '@/contexts/AuthContext';
import { supabase } from '@/lib/supabase';
import GuestForm from '@/components/guests/GuestForm';
import GuestSMS from '@/components/guests/GuestSMS';
import GuestStats from '@/components/guests/GuestStats';
import GuestList from '@/components/guests/GuestList';
import BulkInvitation from '@/components/guests/BulkInvitation';
import GuestsHeader from '@/components/guests/GuestsHeader';
import { Dialog, DialogContent } from '@/components/ui/dialog';

const GuestsContent = () => {
  const [guests, setGuests] = useState<any[]>([]);
  const [isAdding, setIsAdding] = useState(false);
  const [showSMSDialog, setShowSMSDialog] = useState(false);
  const [selectedGuest, setSelectedGuest] = useState<any>(null);
  const [showBulkInvitation, setShowBulkInvitation] = useState(false);
  const { toast } = useToast();
  const { user } = useAuth();

  console.log('GuestsContent: Initializing');

  useEffect(() => {
    if (user) {
      fetchGuests();
      
      // Set up real-time subscription
      const channel = supabase
        .channel('guests-changes')
        .on(
          'postgres_changes',
          {
            event: '*', // Listen to all events (INSERT, UPDATE, DELETE)
            schema: 'public',
            table: 'guests',
            filter: `user_id=eq.${user.id}`,
          },
          (payload) => {
            console.log('Real-time update received:', payload);
            
            // Handle different types of changes
            if (payload.eventType === 'INSERT') {
              setGuests((prev) => [payload.new, ...prev]);
            } else if (payload.eventType === 'DELETE') {
              setGuests((prev) => prev.filter((guest) => guest.id !== payload.old.id));
            } else if (payload.eventType === 'UPDATE') {
              setGuests((prev) =>
                prev.map((guest) => (guest.id === payload.new.id ? payload.new : guest))
              );
            }
            
            toast({
              title: "Guest List Updated",
              description: "The guest list has been automatically updated.",
            });
          }
        )
        .subscribe();

      // Cleanup subscription on unmount
      return () => {
        supabase.removeChannel(channel);
      };
    }
  }, [user]);

  const fetchGuests = async () => {
    try {
      console.log('Fetching guests for user:', user?.id);
      const { data, error } = await supabase
        .from('guests')
        .select('*')
        .eq('user_id', user?.id)
        .order('created_at', { ascending: false });

      if (error) {
        console.error('Error fetching guests:', error);
        throw error;
      }

      console.log('Fetched guests:', data);
      setGuests(data || []);
    } catch (error: any) {
      console.error('Error in fetchGuests:', error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to load guests",
      });
    }
  };

  const handleAddGuest = async (guestData: any) => {
    try {
      console.log('Adding new guest:', guestData);
      const { data, error } = await supabase
        .from('guests')
        .insert([{ ...guestData, user_id: user?.id }])
        .select()
        .single();

      if (error) throw error;

      console.log('Successfully added guest:', data);
      setIsAdding(false);
      toast({
        title: "Success",
        description: "Guest added successfully",
      });
    } catch (error: any) {
      console.error('Error adding guest:', error);
      toast({
        variant: "destructive",
        title: "Error",
        description: error.message || "Failed to add guest",
      });
    }
  };

  const handleUpdateGuest = async (id: string, updates: any) => {
    try {
      console.log('Updating guest:', id, updates);
      const { data, error } = await supabase
        .from('guests')
        .update(updates)
        .eq('id', id)
        .select()
        .single();

      if (error) throw error;

      console.log('Successfully updated guest:', data);
      toast({
        title: "Success",
        description: "Guest updated successfully",
      });
    } catch (error: any) {
      console.error('Error updating guest:', error);
      toast({
        variant: "destructive",
        title: "Error",
        description: error.message || "Failed to update guest",
      });
    }
  };

  const handleDeleteGuest = async (id: string) => {
    try {
      console.log('Deleting guest:', id);
      const { error } = await supabase
        .from('guests')
        .delete()
        .eq('id', id);

      if (error) throw error;

      console.log('Successfully deleted guest:', id);
      toast({
        title: "Success",
        description: "Guest deleted successfully",
      });
    } catch (error: any) {
      console.error('Error deleting guest:', error);
      toast({
        variant: "destructive",
        title: "Error",
        description: error.message || "Failed to delete guest",
      });
    }
  };

  return (
    <>
      <GuestsHeader 
        onAddGuest={() => setIsAdding(true)}
        onManageInvitations={() => setShowBulkInvitation(true)}
      />

      <GuestStats guests={guests || []} />

      {isAdding && (
        <Dialog open={isAdding} onOpenChange={setIsAdding}>
          <DialogContent>
            <GuestForm onSubmit={handleAddGuest} onCancel={() => setIsAdding(false)} />
          </DialogContent>
        </Dialog>
      )}

      {showSMSDialog && selectedGuest && (
        <Dialog open={showSMSDialog} onOpenChange={setShowSMSDialog}>
          <DialogContent>
            <GuestSMS 
              guest={selectedGuest}
              onClose={() => {
                setShowSMSDialog(false);
                setSelectedGuest(null);
              }}
            />
          </DialogContent>
        </Dialog>
      )}

      {showBulkInvitation && (
        <Dialog open={showBulkInvitation} onOpenChange={setShowBulkInvitation}>
          <DialogContent className="max-w-3xl">
            <BulkInvitation 
              guests={guests}
              onClose={() => setShowBulkInvitation(false)}
            />
          </DialogContent>
        </Dialog>
      )}

      <GuestList
        guests={guests}
        onUpdate={handleUpdateGuest}
        onDelete={handleDeleteGuest}
        onSendSMS={(guest) => {
          setSelectedGuest(guest);
          setShowSMSDialog(true);
        }}
      />
    </>
  );
};

export default GuestsContent;