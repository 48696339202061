import React from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { supabase } from '@/lib/supabase';
import { useAuth } from '@/contexts/AuthContext';
import { useToast } from '@/hooks/use-toast';
import { UserSupplier } from './types';
import { UpdateSupplierDialog } from './UpdateSupplierDialog';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { DragDropContext, DropResult } from '@hello-pangea/dnd';
import { PipelineColumn } from './PipelineColumn';
import { SupplierStats } from './SupplierStats';
import { SupplierListView } from './SupplierListView';
import { Card, CardContent } from '@/components/ui/card';

export const MySuppliers = () => {
  const { user } = useAuth();
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const [editingSupplier, setEditingSupplier] = React.useState<UserSupplier | null>(null);

  // Fetch user suppliers with joined supplier data
  const { data: userSuppliers = [], isLoading } = useQuery({
    queryKey: ['userSuppliers'],
    queryFn: async () => {
      console.log('Fetching user suppliers');
      if (!user) return [];
      
      const { data, error } = await supabase
        .from('user_suppliers')
        .select(`
          *,
          supplier:suppliers(*)
        `)
        .eq('user_id', user.id)
        .order('created_at', { ascending: false });

      if (error) {
        console.error('Error fetching suppliers:', error);
        throw error;
      }
      console.log('Fetched suppliers:', data);
      return data as (UserSupplier & { supplier: any })[];
    },
    enabled: !!user,
  });

  // Set up real-time subscription
  React.useEffect(() => {
    if (!user) return;

    const channel = supabase
      .channel('user-suppliers-changes')
      .on(
        'postgres_changes',
        {
          event: '*', // Listen to all events (INSERT, UPDATE, DELETE)
          schema: 'public',
          table: 'user_suppliers',
          filter: `user_id=eq.${user.id}`,
        },
        (payload) => {
          console.log('Real-time update received:', payload);
          // Invalidate and refetch the suppliers query
          queryClient.invalidateQueries({ queryKey: ['userSuppliers'] });
          
          // Show toast notification based on the event type
          const eventMessages = {
            INSERT: 'New supplier added to your list',
            UPDATE: 'Supplier details updated',
            DELETE: 'Supplier removed from your list'
          };
          
          toast({
            title: "Success",
            description: eventMessages[payload.eventType as keyof typeof eventMessages],
          });
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  }, [user, queryClient, toast]);

  const getSupplierDetails = (supplierId: string, category: string) => {
    console.log('Getting supplier details:', { supplierId, category });
    const userSupplier = userSuppliers.find(us => us.supplier_id === supplierId);
    return userSupplier?.supplier;
  };

  const handleDelete = async (supplierId: string) => {
    try {
      const { error } = await supabase
        .from('user_suppliers')
        .delete()
        .eq('id', supplierId);

      if (error) throw error;

      queryClient.invalidateQueries({ queryKey: ['userSuppliers'] });
      toast({
        title: "Success",
        description: "Supplier has been removed from your list",
      });
    } catch (error: any) {
      toast({
        variant: "destructive",
        title: "Error",
        description: error.message,
      });
    }
  };

  const handleDragEnd = async (result: DropResult) => {
    const { destination, source, draggableId } = result;

    if (!destination) return;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    try {
      const { error } = await supabase
        .from('user_suppliers')
        .update({ status: destination.droppableId })
        .eq('id', draggableId);

      if (error) throw error;

      queryClient.invalidateQueries({ queryKey: ['userSuppliers'] });
      toast({
        title: "Success",
        description: "Supplier status updated successfully",
      });
    } catch (error: any) {
      toast({
        variant: "destructive",
        title: "Error",
        description: error.message,
      });
    }
  };

  if (isLoading) {
    return <div>Loading your suppliers...</div>;
  }

  if (!userSuppliers?.length) {
    return (
      <Card>
        <CardContent className="p-6">
          <p className="text-center text-muted-foreground">
            You haven't added any suppliers to your list yet.
          </p>
        </CardContent>
      </Card>
    );
  }

  const suppliersByStatus = {
    interested: userSuppliers.filter(s => s.status === 'interested'),
    negotiating: userSuppliers.filter(s => s.status === 'negotiating'),
    confirmed: userSuppliers.filter(s => s.status === 'confirmed'),
    declined: userSuppliers.filter(s => s.status === 'declined')
  };

  return (
    <div className="space-y-6">
      <SupplierStats suppliersByStatus={suppliersByStatus} />

      <Tabs defaultValue="pipeline" className="space-y-4">
        <TabsList>
          <TabsTrigger value="pipeline">Pipeline View</TabsTrigger>
          <TabsTrigger value="list">List View</TabsTrigger>
        </TabsList>

        <TabsContent value="pipeline">
          <DragDropContext onDragEnd={handleDragEnd}>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
              {Object.entries(suppliersByStatus).map(([status, suppliers]) => (
                <PipelineColumn
                  key={status}
                  status={status}
                  suppliers={suppliers}
                  getSupplierDetails={getSupplierDetails}
                  onEdit={setEditingSupplier}
                  onDelete={handleDelete}
                />
              ))}
            </div>
          </DragDropContext>
        </TabsContent>

        <TabsContent value="list">
          <SupplierListView
            userSuppliers={userSuppliers}
            getSupplierDetails={getSupplierDetails}
            onEdit={setEditingSupplier}
            onDelete={handleDelete}
          />
        </TabsContent>
      </Tabs>

      {editingSupplier && (
        <UpdateSupplierDialog
          supplier={editingSupplier}
          onClose={() => setEditingSupplier(null)}
        />
      )}
    </div>
  );
};